@font-face {
  font-family: remixicon;
  src: url(../fonts/remix/remixicon.eot);
  src: url(../fonts/remix/remixicon.eot#iefix) format("embedded-opentype"), url(../fonts/remix/remixicon.woff2) format("woff2"), url(../fonts/remix/remixicon.woff) format("woff"), url(../fonts/remix/remixicon.ttf) format("truetype"), url(../fonts/remix/remixicon.svg) format("svg");
  font-display: swap
}

[class*=" ri-"], [class^=ri-] {
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ri-lg {
  font-size: 1.3333em;
  line-height: .75em;
  vertical-align: -.0667em
}

.ri-xl {
  font-size: 1.5em;
  line-height: .6666em;
  vertical-align: -.075em
}

.ri-xxs {
  font-size: .5em
}

.ri-xs {
  font-size: .75em
}

.ri-sm {
  font-size: .875em
}

.ri-1x {
  font-size: 1em
}

.ri-2x {
  font-size: 2em
}

.ri-3x {
  font-size: 3em
}

.ri-4x {
  font-size: 4em
}

.ri-5x {
  font-size: 5em
}

.ri-6x {
  font-size: 6em
}

.ri-7x {
  font-size: 7em
}

.ri-8x {
  font-size: 8em
}

.ri-9x {
  font-size: 9em
}

.ri-10x {
  font-size: 10em
}

.ri-fw {
  text-align: center;
  width: 1.25em
}

.ri-24-hours-fill:before {
  content: "\EA01"
}

.ri-24-hours-line:before {
  content: "\EA02"
}

.ri-4k-fill:before {
  content: "\EA03"
}

.ri-4k-line:before {
  content: "\EA04"
}

.ri-a-b:before {
  content: "\EA05"
}

.ri-account-box-fill:before {
  content: "\EA06"
}

.ri-account-box-line:before {
  content: "\EA07"
}

.ri-account-circle-fill:before {
  content: "\EA08"
}

.ri-account-circle-line:before {
  content: "\EA09"
}

.ri-account-pin-box-fill:before {
  content: "\EA0A"
}

.ri-account-pin-box-line:before {
  content: "\EA0B"
}

.ri-account-pin-circle-fill:before {
  content: "\EA0C"
}

.ri-account-pin-circle-line:before {
  content: "\EA0D"
}

.ri-add-box-fill:before {
  content: "\EA0E"
}

.ri-add-box-line:before {
  content: "\EA0F"
}

.ri-add-circle-fill:before {
  content: "\EA10"
}

.ri-add-circle-line:before {
  content: "\EA11"
}

.ri-add-fill:before {
  content: "\EA12"
}

.ri-add-line:before {
  content: "\EA13"
}

.ri-admin-fill:before {
  content: "\EA14"
}

.ri-admin-line:before {
  content: "\EA15"
}

.ri-advertisement-fill:before {
  content: "\EA16"
}

.ri-advertisement-line:before {
  content: "\EA17"
}

.ri-airplay-fill:before {
  content: "\EA18"
}

.ri-airplay-line:before {
  content: "\EA19"
}

.ri-alarm-fill:before {
  content: "\EA1A"
}

.ri-alarm-line:before {
  content: "\EA1B"
}

.ri-alarm-warning-fill:before {
  content: "\EA1C"
}

.ri-alarm-warning-line:before {
  content: "\EA1D"
}

.ri-album-fill:before {
  content: "\EA1E"
}

.ri-album-line:before {
  content: "\EA1F"
}

.ri-alert-fill:before {
  content: "\EA20"
}

.ri-alert-line:before {
  content: "\EA21"
}

.ri-aliens-fill:before {
  content: "\EA22"
}

.ri-aliens-line:before {
  content: "\EA23"
}

.ri-align-bottom:before {
  content: "\EA24"
}

.ri-align-center:before {
  content: "\EA25"
}

.ri-align-justify:before {
  content: "\EA26"
}

.ri-align-left:before {
  content: "\EA27"
}

.ri-align-right:before {
  content: "\EA28"
}

.ri-align-top:before {
  content: "\EA29"
}

.ri-align-vertically:before {
  content: "\EA2A"
}

.ri-alipay-fill:before {
  content: "\EA2B"
}

.ri-alipay-line:before {
  content: "\EA2C"
}

.ri-amazon-fill:before {
  content: "\EA2D"
}

.ri-amazon-line:before {
  content: "\EA2E"
}

.ri-anchor-fill:before {
  content: "\EA2F"
}

.ri-anchor-line:before {
  content: "\EA30"
}

.ri-ancient-gate-fill:before {
  content: "\EA31"
}

.ri-ancient-gate-line:before {
  content: "\EA32"
}

.ri-ancient-pavilion-fill:before {
  content: "\EA33"
}

.ri-ancient-pavilion-line:before {
  content: "\EA34"
}

.ri-android-fill:before {
  content: "\EA35"
}

.ri-android-line:before {
  content: "\EA36"
}

.ri-angularjs-fill:before {
  content: "\EA37"
}

.ri-angularjs-line:before {
  content: "\EA38"
}

.ri-anticlockwise-2-fill:before {
  content: "\EA39"
}

.ri-anticlockwise-2-line:before {
  content: "\EA3A"
}

.ri-anticlockwise-fill:before {
  content: "\EA3B"
}

.ri-anticlockwise-line:before {
  content: "\EA3C"
}

.ri-app-store-fill:before {
  content: "\EA3D"
}

.ri-app-store-line:before {
  content: "\EA3E"
}

.ri-apple-fill:before {
  content: "\EA3F"
}

.ri-apple-line:before {
  content: "\EA40"
}

.ri-apps-2-fill:before {
  content: "\EA41"
}

.ri-apps-2-line:before {
  content: "\EA42"
}

.ri-apps-fill:before {
  content: "\EA43"
}

.ri-apps-line:before {
  content: "\EA44"
}

.ri-archive-drawer-fill:before {
  content: "\EA45"
}

.ri-archive-drawer-line:before {
  content: "\EA46"
}

.ri-archive-fill:before {
  content: "\EA47"
}

.ri-archive-line:before {
  content: "\EA48"
}

.ri-arrow-down-circle-fill:before {
  content: "\EA49"
}

.ri-arrow-down-circle-line:before {
  content: "\EA4A"
}

.ri-arrow-down-fill:before {
  content: "\EA4B"
}

.ri-arrow-down-line:before {
  content: "\EA4C"
}

.ri-arrow-down-s-fill:before {
  content: "\EA4D"
}

.ri-arrow-down-s-line:before {
  content: "\EA4E"
}

.ri-arrow-drop-down-fill:before {
  content: "\EA4F"
}

.ri-arrow-drop-down-line:before {
  content: "\EA50"
}

.ri-arrow-drop-left-fill:before {
  content: "\EA51"
}

.ri-arrow-drop-left-line:before {
  content: "\EA52"
}

.ri-arrow-drop-right-fill:before {
  content: "\EA53"
}

.ri-arrow-drop-right-line:before {
  content: "\EA54"
}

.ri-arrow-drop-up-fill:before {
  content: "\EA55"
}

.ri-arrow-drop-up-line:before {
  content: "\EA56"
}

.ri-arrow-go-back-fill:before {
  content: "\EA57"
}

.ri-arrow-go-back-line:before {
  content: "\EA58"
}

.ri-arrow-go-forward-fill:before {
  content: "\EA59"
}

.ri-arrow-go-forward-line:before {
  content: "\EA5A"
}

.ri-arrow-left-circle-fill:before {
  content: "\EA5B"
}

.ri-arrow-left-circle-line:before {
  content: "\EA5C"
}

.ri-arrow-left-down-fill:before {
  content: "\EA5D"
}

.ri-arrow-left-down-line:before {
  content: "\EA5E"
}

.ri-arrow-left-fill:before {
  content: "\EA5F"
}

.ri-arrow-left-line:before {
  content: "\EA60"
}

.ri-arrow-left-right-fill:before {
  content: "\EA61"
}

.ri-arrow-left-right-line:before {
  content: "\EA62"
}

.ri-arrow-left-s-fill:before {
  content: "\EA63"
}

.ri-arrow-left-s-line:before {
  content: "\EA64"
}

.ri-arrow-left-up-fill:before {
  content: "\EA65"
}

.ri-arrow-left-up-line:before {
  content: "\EA66"
}

.ri-arrow-right-circle-fill:before {
  content: "\EA67"
}

.ri-arrow-right-circle-line:before {
  content: "\EA68"
}

.ri-arrow-right-down-fill:before {
  content: "\EA69"
}

.ri-arrow-right-down-line:before {
  content: "\EA6A"
}

.ri-arrow-right-fill:before {
  content: "\EA6B"
}

.ri-arrow-right-line:before {
  content: "\EA6C"
}

.ri-arrow-right-s-fill:before {
  content: "\EA6D"
}

.ri-arrow-right-s-line:before {
  content: "\EA6E"
}

.ri-arrow-right-up-fill:before {
  content: "\EA6F"
}

.ri-arrow-right-up-line:before {
  content: "\EA70"
}

.ri-arrow-up-circle-fill:before {
  content: "\EA71"
}

.ri-arrow-up-circle-line:before {
  content: "\EA72"
}

.ri-arrow-up-down-fill:before {
  content: "\EA73"
}

.ri-arrow-up-down-line:before {
  content: "\EA74"
}

.ri-arrow-up-fill:before {
  content: "\EA75"
}

.ri-arrow-up-line:before {
  content: "\EA76"
}

.ri-arrow-up-s-fill:before {
  content: "\EA77"
}

.ri-arrow-up-s-line:before {
  content: "\EA78"
}

.ri-artboard-2-fill:before {
  content: "\EA79"
}

.ri-artboard-2-line:before {
  content: "\EA7A"
}

.ri-artboard-fill:before {
  content: "\EA7B"
}

.ri-artboard-line:before {
  content: "\EA7C"
}

.ri-article-fill:before {
  content: "\EA7D"
}

.ri-article-line:before {
  content: "\EA7E"
}

.ri-aspect-ratio-fill:before {
  content: "\EA7F"
}

.ri-aspect-ratio-line:before {
  content: "\EA80"
}

.ri-asterisk:before {
  content: "\EA81"
}

.ri-at-fill:before {
  content: "\EA82"
}

.ri-at-line:before {
  content: "\EA83"
}

.ri-attachment-2:before {
  content: "\EA84"
}

.ri-attachment-fill:before {
  content: "\EA85"
}

.ri-attachment-line:before {
  content: "\EA86"
}

.ri-auction-fill:before {
  content: "\EA87"
}

.ri-auction-line:before {
  content: "\EA88"
}

.ri-award-fill:before {
  content: "\EA89"
}

.ri-award-line:before {
  content: "\EA8A"
}

.ri-baidu-fill:before {
  content: "\EA8B"
}

.ri-baidu-line:before {
  content: "\EA8C"
}

.ri-ball-pen-fill:before {
  content: "\EA8D"
}

.ri-ball-pen-line:before {
  content: "\EA8E"
}

.ri-bank-card-2-fill:before {
  content: "\EA8F"
}

.ri-bank-card-2-line:before {
  content: "\EA90"
}

.ri-bank-card-fill:before {
  content: "\EA91"
}

.ri-bank-card-line:before {
  content: "\EA92"
}

.ri-bank-fill:before {
  content: "\EA93"
}

.ri-bank-line:before {
  content: "\EA94"
}

.ri-bar-chart-2-fill:before {
  content: "\EA95"
}

.ri-bar-chart-2-line:before {
  content: "\EA96"
}

.ri-bar-chart-box-fill:before {
  content: "\EA97"
}

.ri-bar-chart-box-line:before {
  content: "\EA98"
}

.ri-bar-chart-fill:before {
  content: "\EA99"
}

.ri-bar-chart-grouped-fill:before {
  content: "\EA9A"
}

.ri-bar-chart-grouped-line:before {
  content: "\EA9B"
}

.ri-bar-chart-horizontal-fill:before {
  content: "\EA9C"
}

.ri-bar-chart-horizontal-line:before {
  content: "\EA9D"
}

.ri-bar-chart-line:before {
  content: "\EA9E"
}

.ri-barcode-box-fill:before {
  content: "\EA9F"
}

.ri-barcode-box-line:before {
  content: "\EAA0"
}

.ri-barcode-fill:before {
  content: "\EAA1"
}

.ri-barcode-line:before {
  content: "\EAA2"
}

.ri-barricade-fill:before {
  content: "\EAA3"
}

.ri-barricade-line:before {
  content: "\EAA4"
}

.ri-base-station-fill:before {
  content: "\EAA5"
}

.ri-base-station-line:before {
  content: "\EAA6"
}

.ri-basketball-fill:before {
  content: "\EAA7"
}

.ri-basketball-line:before {
  content: "\EAA8"
}

.ri-battery-2-charge-fill:before {
  content: "\EAA9"
}

.ri-battery-2-charge-line:before {
  content: "\EAAA"
}

.ri-battery-2-fill:before {
  content: "\EAAB"
}

.ri-battery-2-line:before {
  content: "\EAAC"
}

.ri-battery-charge-fill:before {
  content: "\EAAD"
}

.ri-battery-charge-line:before {
  content: "\EAAE"
}

.ri-battery-fill:before {
  content: "\EAAF"
}

.ri-battery-line:before {
  content: "\EAB0"
}

.ri-battery-low-fill:before {
  content: "\EAB1"
}

.ri-battery-low-line:before {
  content: "\EAB2"
}

.ri-battery-saver-fill:before {
  content: "\EAB3"
}

.ri-battery-saver-line:before {
  content: "\EAB4"
}

.ri-battery-share-fill:before {
  content: "\EAB5"
}

.ri-battery-share-line:before {
  content: "\EAB6"
}

.ri-bear-smile-fill:before {
  content: "\EAB7"
}

.ri-bear-smile-line:before {
  content: "\EAB8"
}

.ri-behance-fill:before {
  content: "\EAB9"
}

.ri-behance-line:before {
  content: "\EABA"
}

.ri-bell-fill:before {
  content: "\EABB"
}

.ri-bell-line:before {
  content: "\EABC"
}

.ri-bike-fill:before {
  content: "\EABD"
}

.ri-bike-line:before {
  content: "\EABE"
}

.ri-bilibili-fill:before {
  content: "\EABF"
}

.ri-bilibili-line:before {
  content: "\EAC0"
}

.ri-bill-fill:before {
  content: "\EAC1"
}

.ri-bill-line:before {
  content: "\EAC2"
}

.ri-billiards-fill:before {
  content: "\EAC3"
}

.ri-billiards-line:before {
  content: "\EAC4"
}

.ri-bit-coin-fill:before {
  content: "\EAC5"
}

.ri-bit-coin-line:before {
  content: "\EAC6"
}

.ri-blaze-fill:before {
  content: "\EAC7"
}

.ri-blaze-line:before {
  content: "\EAC8"
}

.ri-bluetooth-connect-fill:before {
  content: "\EAC9"
}

.ri-bluetooth-connect-line:before {
  content: "\EACA"
}

.ri-bluetooth-fill:before {
  content: "\EACB"
}

.ri-bluetooth-line:before {
  content: "\EACC"
}

.ri-blur-off-fill:before {
  content: "\EACD"
}

.ri-blur-off-line:before {
  content: "\EACE"
}

.ri-body-scan-fill:before {
  content: "\EACF"
}

.ri-body-scan-line:before {
  content: "\EAD0"
}

.ri-bold:before {
  content: "\EAD1"
}

.ri-book-2-fill:before {
  content: "\EAD2"
}

.ri-book-2-line:before {
  content: "\EAD3"
}

.ri-book-3-fill:before {
  content: "\EAD4"
}

.ri-book-3-line:before {
  content: "\EAD5"
}

.ri-book-fill:before {
  content: "\EAD6"
}

.ri-book-line:before {
  content: "\EAD7"
}

.ri-book-mark-fill:before {
  content: "\EAD8"
}

.ri-book-mark-line:before {
  content: "\EAD9"
}

.ri-book-open-fill:before {
  content: "\EADA"
}

.ri-book-open-line:before {
  content: "\EADB"
}

.ri-book-read-fill:before {
  content: "\EADC"
}

.ri-book-read-line:before {
  content: "\EADD"
}

.ri-booklet-fill:before {
  content: "\EADE"
}

.ri-booklet-line:before {
  content: "\EADF"
}

.ri-bookmark-2-fill:before {
  content: "\EAE0"
}

.ri-bookmark-2-line:before {
  content: "\EAE1"
}

.ri-bookmark-3-fill:before {
  content: "\EAE2"
}

.ri-bookmark-3-line:before {
  content: "\EAE3"
}

.ri-bookmark-fill:before {
  content: "\EAE4"
}

.ri-bookmark-line:before {
  content: "\EAE5"
}

.ri-boxing-fill:before {
  content: "\EAE6"
}

.ri-boxing-line:before {
  content: "\EAE7"
}

.ri-braces-fill:before {
  content: "\EAE8"
}

.ri-braces-line:before {
  content: "\EAE9"
}

.ri-brackets-fill:before {
  content: "\EAEA"
}

.ri-brackets-line:before {
  content: "\EAEB"
}

.ri-briefcase-2-fill:before {
  content: "\EAEC"
}

.ri-briefcase-2-line:before {
  content: "\EAED"
}

.ri-briefcase-3-fill:before {
  content: "\EAEE"
}

.ri-briefcase-3-line:before {
  content: "\EAEF"
}

.ri-briefcase-4-fill:before {
  content: "\EAF0"
}

.ri-briefcase-4-line:before {
  content: "\EAF1"
}

.ri-briefcase-5-fill:before {
  content: "\EAF2"
}

.ri-briefcase-5-line:before {
  content: "\EAF3"
}

.ri-briefcase-fill:before {
  content: "\EAF4"
}

.ri-briefcase-line:before {
  content: "\EAF5"
}

.ri-bring-forward:before {
  content: "\EAF6"
}

.ri-bring-to-front:before {
  content: "\EAF7"
}

.ri-broadcast-fill:before {
  content: "\EAF8"
}

.ri-broadcast-line:before {
  content: "\EAF9"
}

.ri-brush-2-fill:before {
  content: "\EAFA"
}

.ri-brush-2-line:before {
  content: "\EAFB"
}

.ri-brush-3-fill:before {
  content: "\EAFC"
}

.ri-brush-3-line:before {
  content: "\EAFD"
}

.ri-brush-4-fill:before {
  content: "\EAFE"
}

.ri-brush-4-line:before {
  content: "\EAFF"
}

.ri-brush-fill:before {
  content: "\EB00"
}

.ri-brush-line:before {
  content: "\EB01"
}

.ri-bubble-chart-fill:before {
  content: "\EB02"
}

.ri-bubble-chart-line:before {
  content: "\EB03"
}

.ri-bug-2-fill:before {
  content: "\EB04"
}

.ri-bug-2-line:before {
  content: "\EB05"
}

.ri-bug-fill:before {
  content: "\EB06"
}

.ri-bug-line:before {
  content: "\EB07"
}

.ri-building-2-fill:before {
  content: "\EB08"
}

.ri-building-2-line:before {
  content: "\EB09"
}

.ri-building-3-fill:before {
  content: "\EB0A"
}

.ri-building-3-line:before {
  content: "\EB0B"
}

.ri-building-4-fill:before {
  content: "\EB0C"
}

.ri-building-4-line:before {
  content: "\EB0D"
}

.ri-building-fill:before {
  content: "\EB0E"
}

.ri-building-line:before {
  content: "\EB0F"
}

.ri-bus-2-fill:before {
  content: "\EB10"
}

.ri-bus-2-line:before {
  content: "\EB11"
}

.ri-bus-fill:before {
  content: "\EB12"
}

.ri-bus-line:before {
  content: "\EB13"
}

.ri-bus-wifi-fill:before {
  content: "\EB14"
}

.ri-bus-wifi-line:before {
  content: "\EB15"
}

.ri-cactus-fill:before {
  content: "\EB16"
}

.ri-cactus-line:before {
  content: "\EB17"
}

.ri-cake-2-fill:before {
  content: "\EB18"
}

.ri-cake-2-line:before {
  content: "\EB19"
}

.ri-cake-3-fill:before {
  content: "\EB1A"
}

.ri-cake-3-line:before {
  content: "\EB1B"
}

.ri-cake-fill:before {
  content: "\EB1C"
}

.ri-cake-line:before {
  content: "\EB1D"
}

.ri-calculator-fill:before {
  content: "\EB1E"
}

.ri-calculator-line:before {
  content: "\EB1F"
}

.ri-calendar-2-fill:before {
  content: "\EB20"
}

.ri-calendar-2-line:before {
  content: "\EB21"
}

.ri-calendar-check-fill:before {
  content: "\EB22"
}

.ri-calendar-check-line:before {
  content: "\EB23"
}

.ri-calendar-event-fill:before {
  content: "\EB24"
}

.ri-calendar-event-line:before {
  content: "\EB25"
}

.ri-calendar-fill:before {
  content: "\EB26"
}

.ri-calendar-line:before {
  content: "\EB27"
}

.ri-calendar-todo-fill:before {
  content: "\EB28"
}

.ri-calendar-todo-line:before {
  content: "\EB29"
}

.ri-camera-2-fill:before {
  content: "\EB2A"
}

.ri-camera-2-line:before {
  content: "\EB2B"
}

.ri-camera-3-fill:before {
  content: "\EB2C"
}

.ri-camera-3-line:before {
  content: "\EB2D"
}

.ri-camera-fill:before {
  content: "\EB2E"
}

.ri-camera-lens-fill:before {
  content: "\EB2F"
}

.ri-camera-lens-line:before {
  content: "\EB30"
}

.ri-camera-line:before {
  content: "\EB31"
}

.ri-camera-off-fill:before {
  content: "\EB32"
}

.ri-camera-off-line:before {
  content: "\EB33"
}

.ri-camera-switch-fill:before {
  content: "\EB34"
}

.ri-camera-switch-line:before {
  content: "\EB35"
}

.ri-capsule-fill:before {
  content: "\EB36"
}

.ri-capsule-line:before {
  content: "\EB37"
}

.ri-car-fill:before {
  content: "\EB38"
}

.ri-car-line:before {
  content: "\EB39"
}

.ri-car-washing-fill:before {
  content: "\EB3A"
}

.ri-car-washing-line:before {
  content: "\EB3B"
}

.ri-caravan-fill:before {
  content: "\EB3C"
}

.ri-caravan-line:before {
  content: "\EB3D"
}

.ri-cast-fill:before {
  content: "\EB3E"
}

.ri-cast-line:before {
  content: "\EB3F"
}

.ri-cellphone-fill:before {
  content: "\EB40"
}

.ri-cellphone-line:before {
  content: "\EB41"
}

.ri-celsius-fill:before {
  content: "\EB42"
}

.ri-celsius-line:before {
  content: "\EB43"
}

.ri-centos-fill:before {
  content: "\EB44"
}

.ri-centos-line:before {
  content: "\EB45"
}

.ri-character-recognition-fill:before {
  content: "\EB46"
}

.ri-character-recognition-line:before {
  content: "\EB47"
}

.ri-charging-pile-2-fill:before {
  content: "\EB48"
}

.ri-charging-pile-2-line:before {
  content: "\EB49"
}

.ri-charging-pile-fill:before {
  content: "\EB4A"
}

.ri-charging-pile-line:before {
  content: "\EB4B"
}

.ri-chat-1-fill:before {
  content: "\EB4C"
}

.ri-chat-1-line:before {
  content: "\EB4D"
}

.ri-chat-2-fill:before {
  content: "\EB4E"
}

.ri-chat-2-line:before {
  content: "\EB4F"
}

.ri-chat-3-fill:before {
  content: "\EB50"
}

.ri-chat-3-line:before {
  content: "\EB51"
}

.ri-chat-4-fill:before {
  content: "\EB52"
}

.ri-chat-4-line:before {
  content: "\EB53"
}

.ri-chat-check-fill:before {
  content: "\EB54"
}

.ri-chat-check-line:before {
  content: "\EB55"
}

.ri-chat-delete-fill:before {
  content: "\EB56"
}

.ri-chat-delete-line:before {
  content: "\EB57"
}

.ri-chat-download-fill:before {
  content: "\EB58"
}

.ri-chat-download-line:before {
  content: "\EB59"
}

.ri-chat-follow-up-fill:before {
  content: "\EB5A"
}

.ri-chat-follow-up-line:before {
  content: "\EB5B"
}

.ri-chat-forward-fill:before {
  content: "\EB5C"
}

.ri-chat-forward-line:before {
  content: "\EB5D"
}

.ri-chat-heart-fill:before {
  content: "\EB5E"
}

.ri-chat-heart-line:before {
  content: "\EB5F"
}

.ri-chat-history-fill:before {
  content: "\EB60"
}

.ri-chat-history-line:before {
  content: "\EB61"
}

.ri-chat-new-fill:before {
  content: "\EB62"
}

.ri-chat-new-line:before {
  content: "\EB63"
}

.ri-chat-off-fill:before {
  content: "\EB64"
}

.ri-chat-off-line:before {
  content: "\EB65"
}

.ri-chat-poll-fill:before {
  content: "\EB66"
}

.ri-chat-poll-line:before {
  content: "\EB67"
}

.ri-chat-private-fill:before {
  content: "\EB68"
}

.ri-chat-private-line:before {
  content: "\EB69"
}

.ri-chat-quote-fill:before {
  content: "\EB6A"
}

.ri-chat-quote-line:before {
  content: "\EB6B"
}

.ri-chat-settings-fill:before {
  content: "\EB6C"
}

.ri-chat-settings-line:before {
  content: "\EB6D"
}

.ri-chat-smile-2-fill:before {
  content: "\EB6E"
}

.ri-chat-smile-2-line:before {
  content: "\EB6F"
}

.ri-chat-smile-3-fill:before {
  content: "\EB70"
}

.ri-chat-smile-3-line:before {
  content: "\EB71"
}

.ri-chat-smile-fill:before {
  content: "\EB72"
}

.ri-chat-smile-line:before {
  content: "\EB73"
}

.ri-chat-upload-fill:before {
  content: "\EB74"
}

.ri-chat-upload-line:before {
  content: "\EB75"
}

.ri-chat-voice-fill:before {
  content: "\EB76"
}

.ri-chat-voice-line:before {
  content: "\EB77"
}

.ri-check-double-fill:before {
  content: "\EB78"
}

.ri-check-double-line:before {
  content: "\EB79"
}

.ri-check-fill:before {
  content: "\EB7A"
}

.ri-check-line:before {
  content: "\EB7B"
}

.ri-checkbox-blank-circle-fill:before {
  content: "\EB7C"
}

.ri-checkbox-blank-circle-line:before {
  content: "\EB7D"
}

.ri-checkbox-blank-fill:before {
  content: "\EB7E"
}

.ri-checkbox-blank-line:before {
  content: "\EB7F"
}

.ri-checkbox-circle-fill:before {
  content: "\EB80"
}

.ri-checkbox-circle-line:before {
  content: "\EB81"
}

.ri-checkbox-fill:before {
  content: "\EB82"
}

.ri-checkbox-indeterminate-fill:before {
  content: "\EB83"
}

.ri-checkbox-indeterminate-line:before {
  content: "\EB84"
}

.ri-checkbox-line:before {
  content: "\EB85"
}

.ri-checkbox-multiple-blank-fill:before {
  content: "\EB86"
}

.ri-checkbox-multiple-blank-line:before {
  content: "\EB87"
}

.ri-checkbox-multiple-fill:before {
  content: "\EB88"
}

.ri-checkbox-multiple-line:before {
  content: "\EB89"
}

.ri-china-railway-fill:before {
  content: "\EB8A"
}

.ri-china-railway-line:before {
  content: "\EB8B"
}

.ri-chrome-fill:before {
  content: "\EB8C"
}

.ri-chrome-line:before {
  content: "\EB8D"
}

.ri-clapperboard-fill:before {
  content: "\EB8E"
}

.ri-clapperboard-line:before {
  content: "\EB8F"
}

.ri-clipboard-fill:before {
  content: "\EB90"
}

.ri-clipboard-line:before {
  content: "\EB91"
}

.ri-clockwise-2-fill:before {
  content: "\EB92"
}

.ri-clockwise-2-line:before {
  content: "\EB93"
}

.ri-clockwise-fill:before {
  content: "\EB94"
}

.ri-clockwise-line:before {
  content: "\EB95"
}

.ri-close-circle-fill:before {
  content: "\EB96"
}

.ri-close-circle-line:before {
  content: "\EB97"
}

.ri-close-fill:before {
  content: "\EB98"
}

.ri-close-line:before {
  content: "\EB99"
}

.ri-closed-captioning-fill:before {
  content: "\EB9A"
}

.ri-closed-captioning-line:before {
  content: "\EB9B"
}

.ri-cloud-fill:before {
  content: "\EB9C"
}

.ri-cloud-line:before {
  content: "\EB9D"
}

.ri-cloud-off-fill:before {
  content: "\EB9E"
}

.ri-cloud-off-line:before {
  content: "\EB9F"
}

.ri-cloud-windy-fill:before {
  content: "\EBA0"
}

.ri-cloud-windy-line:before {
  content: "\EBA1"
}

.ri-cloudy-2-fill:before {
  content: "\EBA2"
}

.ri-cloudy-2-line:before {
  content: "\EBA3"
}

.ri-cloudy-fill:before {
  content: "\EBA4"
}

.ri-cloudy-line:before {
  content: "\EBA5"
}

.ri-code-box-fill:before {
  content: "\EBA6"
}

.ri-code-box-line:before {
  content: "\EBA7"
}

.ri-code-fill:before {
  content: "\EBA8"
}

.ri-code-line:before {
  content: "\EBA9"
}

.ri-code-s-fill:before {
  content: "\EBAA"
}

.ri-code-s-line:before {
  content: "\EBAB"
}

.ri-code-s-slash-fill:before {
  content: "\EBAC"
}

.ri-code-s-slash-line:before {
  content: "\EBAD"
}

.ri-code-view:before {
  content: "\EBAE"
}

.ri-codepen-fill:before {
  content: "\EBAF"
}

.ri-codepen-line:before {
  content: "\EBB0"
}

.ri-coin-fill:before {
  content: "\EBB1"
}

.ri-coin-line:before {
  content: "\EBB2"
}

.ri-coins-fill:before {
  content: "\EBB3"
}

.ri-coins-line:before {
  content: "\EBB4"
}

.ri-collage-fill:before {
  content: "\EBB5"
}

.ri-collage-line:before {
  content: "\EBB6"
}

.ri-command-fill:before {
  content: "\EBB7"
}

.ri-command-line:before {
  content: "\EBB8"
}

.ri-community-fill:before {
  content: "\EBB9"
}

.ri-community-line:before {
  content: "\EBBA"
}

.ri-compass-2-fill:before {
  content: "\EBBB"
}

.ri-compass-2-line:before {
  content: "\EBBC"
}

.ri-compass-3-fill:before {
  content: "\EBBD"
}

.ri-compass-3-line:before {
  content: "\EBBE"
}

.ri-compass-4-fill:before {
  content: "\EBBF"
}

.ri-compass-4-line:before {
  content: "\EBC0"
}

.ri-compass-discover-fill:before {
  content: "\EBC1"
}

.ri-compass-discover-line:before {
  content: "\EBC2"
}

.ri-compass-fill:before {
  content: "\EBC3"
}

.ri-compass-line:before {
  content: "\EBC4"
}

.ri-compasses-2-fill:before {
  content: "\EBC5"
}

.ri-compasses-2-line:before {
  content: "\EBC6"
}

.ri-compasses-fill:before {
  content: "\EBC7"
}

.ri-compasses-line:before {
  content: "\EBC8"
}

.ri-computer-fill:before {
  content: "\EBC9"
}

.ri-computer-line:before {
  content: "\EBCA"
}

.ri-contacts-book-2-fill:before {
  content: "\EBCB"
}

.ri-contacts-book-2-line:before {
  content: "\EBCC"
}

.ri-contacts-book-fill:before {
  content: "\EBCD"
}

.ri-contacts-book-line:before {
  content: "\EBCE"
}

.ri-contacts-book-upload-fill:before {
  content: "\EBCF"
}

.ri-contacts-book-upload-line:before {
  content: "\EBD0"
}

.ri-contacts-fill:before {
  content: "\EBD1"
}

.ri-contacts-line:before {
  content: "\EBD2"
}

.ri-contrast-2-fill:before {
  content: "\EBD3"
}

.ri-contrast-2-line:before {
  content: "\EBD4"
}

.ri-contrast-drop-2-fill:before {
  content: "\EBD5"
}

.ri-contrast-drop-2-line:before {
  content: "\EBD6"
}

.ri-contrast-drop-fill:before {
  content: "\EBD7"
}

.ri-contrast-drop-line:before {
  content: "\EBD8"
}

.ri-contrast-fill:before {
  content: "\EBD9"
}

.ri-contrast-line:before {
  content: "\EBDA"
}

.ri-copper-coin-fill:before {
  content: "\EBDB"
}

.ri-copper-coin-line:before {
  content: "\EBDC"
}

.ri-copper-diamond-fill:before {
  content: "\EBDD"
}

.ri-copper-diamond-line:before {
  content: "\EBDE"
}

.ri-copyleft-fill:before {
  content: "\EBDF"
}

.ri-copyleft-line:before {
  content: "\EBE0"
}

.ri-copyright-fill:before {
  content: "\EBE1"
}

.ri-copyright-line:before {
  content: "\EBE2"
}

.ri-coreos-fill:before {
  content: "\EBE3"
}

.ri-coreos-line:before {
  content: "\EBE4"
}

.ri-coupon-2-fill:before {
  content: "\EBE5"
}

.ri-coupon-2-line:before {
  content: "\EBE6"
}

.ri-coupon-3-fill:before {
  content: "\EBE7"
}

.ri-coupon-3-line:before {
  content: "\EBE8"
}

.ri-coupon-4-fill:before {
  content: "\EBE9"
}

.ri-coupon-4-line:before {
  content: "\EBEA"
}

.ri-coupon-5-fill:before {
  content: "\EBEB"
}

.ri-coupon-5-line:before {
  content: "\EBEC"
}

.ri-coupon-fill:before {
  content: "\EBED"
}

.ri-coupon-line:before {
  content: "\EBEE"
}

.ri-cpu-fill:before {
  content: "\EBEF"
}

.ri-cpu-line:before {
  content: "\EBF0"
}

.ri-creative-commons-by-fill:before {
  content: "\EBF1"
}

.ri-creative-commons-by-line:before {
  content: "\EBF2"
}

.ri-creative-commons-fill:before {
  content: "\EBF3"
}

.ri-creative-commons-line:before {
  content: "\EBF4"
}

.ri-creative-commons-nc-fill:before {
  content: "\EBF5"
}

.ri-creative-commons-nc-line:before {
  content: "\EBF6"
}

.ri-creative-commons-nd-fill:before {
  content: "\EBF7"
}

.ri-creative-commons-nd-line:before {
  content: "\EBF8"
}

.ri-creative-commons-sa-fill:before {
  content: "\EBF9"
}

.ri-creative-commons-sa-line:before {
  content: "\EBFA"
}

.ri-creative-commons-zero-fill:before {
  content: "\EBFB"
}

.ri-creative-commons-zero-line:before {
  content: "\EBFC"
}

.ri-criminal-fill:before {
  content: "\EBFD"
}

.ri-criminal-line:before {
  content: "\EBFE"
}

.ri-crop-2-fill:before {
  content: "\EBFF"
}

.ri-crop-2-line:before {
  content: "\EC00"
}

.ri-crop-fill:before {
  content: "\EC01"
}

.ri-crop-line:before {
  content: "\EC02"
}

.ri-css3-fill:before {
  content: "\EC03"
}

.ri-css3-line:before {
  content: "\EC04"
}

.ri-cup-fill:before {
  content: "\EC05"
}

.ri-cup-line:before {
  content: "\EC06"
}

.ri-currency-fill:before {
  content: "\EC07"
}

.ri-currency-line:before {
  content: "\EC08"
}

.ri-cursor-fill:before {
  content: "\EC09"
}

.ri-cursor-line:before {
  content: "\EC0A"
}

.ri-customer-service-2-fill:before {
  content: "\EC0B"
}

.ri-customer-service-2-line:before {
  content: "\EC0C"
}

.ri-customer-service-fill:before {
  content: "\EC0D"
}

.ri-customer-service-line:before {
  content: "\EC0E"
}

.ri-dashboard-2-fill:before {
  content: "\EC0F"
}

.ri-dashboard-2-line:before {
  content: "\EC10"
}

.ri-dashboard-3-fill:before {
  content: "\EC11"
}

.ri-dashboard-3-line:before {
  content: "\EC12"
}

.ri-dashboard-fill:before {
  content: "\EC13"
}

.ri-dashboard-line:before {
  content: "\EC14"
}

.ri-database-2-fill:before {
  content: "\EC15"
}

.ri-database-2-line:before {
  content: "\EC16"
}

.ri-database-fill:before {
  content: "\EC17"
}

.ri-database-line:before {
  content: "\EC18"
}

.ri-delete-back-2-fill:before {
  content: "\EC19"
}

.ri-delete-back-2-line:before {
  content: "\EC1A"
}

.ri-delete-back-fill:before {
  content: "\EC1B"
}

.ri-delete-back-line:before {
  content: "\EC1C"
}

.ri-delete-bin-2-fill:before {
  content: "\EC1D"
}

.ri-delete-bin-2-line:before {
  content: "\EC1E"
}

.ri-delete-bin-3-fill:before {
  content: "\EC1F"
}

.ri-delete-bin-3-line:before {
  content: "\EC20"
}

.ri-delete-bin-4-fill:before {
  content: "\EC21"
}

.ri-delete-bin-4-line:before {
  content: "\EC22"
}

.ri-delete-bin-5-fill:before {
  content: "\EC23"
}

.ri-delete-bin-5-line:before {
  content: "\EC24"
}

.ri-delete-bin-6-fill:before {
  content: "\EC25"
}

.ri-delete-bin-6-line:before {
  content: "\EC26"
}

.ri-delete-bin-7-fill:before {
  content: "\EC27"
}

.ri-delete-bin-7-line:before {
  content: "\EC28"
}

.ri-delete-bin-fill:before {
  content: "\EC29"
}

.ri-delete-bin-line:before {
  content: "\EC2A"
}

.ri-delete-column:before {
  content: "\EC2B"
}

.ri-delete-row:before {
  content: "\EC2C"
}

.ri-device-fill:before {
  content: "\EC2D"
}

.ri-device-line:before {
  content: "\EC2E"
}

.ri-device-recover-fill:before {
  content: "\EC2F"
}

.ri-device-recover-line:before {
  content: "\EC30"
}

.ri-dingding-fill:before {
  content: "\EC31"
}

.ri-dingding-line:before {
  content: "\EC32"
}

.ri-direction-fill:before {
  content: "\EC33"
}

.ri-direction-line:before {
  content: "\EC34"
}

.ri-disc-fill:before {
  content: "\EC35"
}

.ri-disc-line:before {
  content: "\EC36"
}

.ri-discord-fill:before {
  content: "\EC37"
}

.ri-discord-line:before {
  content: "\EC38"
}

.ri-discuss-fill:before {
  content: "\EC39"
}

.ri-discuss-line:before {
  content: "\EC3A"
}

.ri-dislike-fill:before {
  content: "\EC3B"
}

.ri-dislike-line:before {
  content: "\EC3C"
}

.ri-disqus-fill:before {
  content: "\EC3D"
}

.ri-disqus-line:before {
  content: "\EC3E"
}

.ri-divide-fill:before {
  content: "\EC3F"
}

.ri-divide-line:before {
  content: "\EC40"
}

.ri-donut-chart-fill:before {
  content: "\EC41"
}

.ri-donut-chart-line:before {
  content: "\EC42"
}

.ri-door-closed-fill:before {
  content: "\EC43"
}

.ri-door-closed-line:before {
  content: "\EC44"
}

.ri-door-fill:before {
  content: "\EC45"
}

.ri-door-line:before {
  content: "\EC46"
}

.ri-door-lock-box-fill:before {
  content: "\EC47"
}

.ri-door-lock-box-line:before {
  content: "\EC48"
}

.ri-door-lock-fill:before {
  content: "\EC49"
}

.ri-door-lock-line:before {
  content: "\EC4A"
}

.ri-door-open-fill:before {
  content: "\EC4B"
}

.ri-door-open-line:before {
  content: "\EC4C"
}

.ri-dossier-fill:before {
  content: "\EC4D"
}

.ri-dossier-line:before {
  content: "\EC4E"
}

.ri-douban-fill:before {
  content: "\EC4F"
}

.ri-douban-line:before {
  content: "\EC50"
}

.ri-double-quotes-l:before {
  content: "\EC51"
}

.ri-double-quotes-r:before {
  content: "\EC52"
}

.ri-download-2-fill:before {
  content: "\EC53"
}

.ri-download-2-line:before {
  content: "\EC54"
}

.ri-download-cloud-2-fill:before {
  content: "\EC55"
}

.ri-download-cloud-2-line:before {
  content: "\EC56"
}

.ri-download-cloud-fill:before {
  content: "\EC57"
}

.ri-download-cloud-line:before {
  content: "\EC58"
}

.ri-download-fill:before {
  content: "\EC59"
}

.ri-download-line:before {
  content: "\EC5A"
}

.ri-draft-fill:before {
  content: "\EC5B"
}

.ri-draft-line:before {
  content: "\EC5C"
}

.ri-drag-drop-fill:before {
  content: "\EC5D"
}

.ri-drag-drop-line:before {
  content: "\EC5E"
}

.ri-drag-move-2-fill:before {
  content: "\EC5F"
}

.ri-drag-move-2-line:before {
  content: "\EC60"
}

.ri-drag-move-fill:before {
  content: "\EC61"
}

.ri-drag-move-line:before {
  content: "\EC62"
}

.ri-dribbble-fill:before {
  content: "\EC63"
}

.ri-dribbble-line:before {
  content: "\EC64"
}

.ri-drive-fill:before {
  content: "\EC65"
}

.ri-drive-line:before {
  content: "\EC66"
}

.ri-drizzle-fill:before {
  content: "\EC67"
}

.ri-drizzle-line:before {
  content: "\EC68"
}

.ri-drop-fill:before {
  content: "\EC69"
}

.ri-drop-line:before {
  content: "\EC6A"
}

.ri-dropbox-fill:before {
  content: "\EC6B"
}

.ri-dropbox-line:before {
  content: "\EC6C"
}

.ri-dual-sim-1-fill:before {
  content: "\EC6D"
}

.ri-dual-sim-1-line:before {
  content: "\EC6E"
}

.ri-dual-sim-2-fill:before {
  content: "\EC6F"
}

.ri-dual-sim-2-line:before {
  content: "\EC70"
}

.ri-dv-fill:before {
  content: "\EC71"
}

.ri-dv-line:before {
  content: "\EC72"
}

.ri-dvd-fill:before {
  content: "\EC73"
}

.ri-dvd-line:before {
  content: "\EC74"
}

.ri-e-bike-2-fill:before {
  content: "\EC75"
}

.ri-e-bike-2-line:before {
  content: "\EC76"
}

.ri-e-bike-fill:before {
  content: "\EC77"
}

.ri-e-bike-line:before {
  content: "\EC78"
}

.ri-earth-fill:before {
  content: "\EC79"
}

.ri-earth-line:before {
  content: "\EC7A"
}

.ri-earthquake-fill:before {
  content: "\EC7B"
}

.ri-earthquake-line:before {
  content: "\EC7C"
}

.ri-edge-fill:before {
  content: "\EC7D"
}

.ri-edge-line:before {
  content: "\EC7E"
}

.ri-edit-2-fill:before {
  content: "\EC7F"
}

.ri-edit-2-line:before {
  content: "\EC80"
}

.ri-edit-box-fill:before {
  content: "\EC81"
}

.ri-edit-box-line:before {
  content: "\EC82"
}

.ri-edit-circle-fill:before {
  content: "\EC83"
}

.ri-edit-circle-line:before {
  content: "\EC84"
}

.ri-edit-fill:before {
  content: "\EC85"
}

.ri-edit-line:before {
  content: "\EC86"
}

.ri-eject-fill:before {
  content: "\EC87"
}

.ri-eject-line:before {
  content: "\EC88"
}

.ri-emotion-2-fill:before {
  content: "\EC89"
}

.ri-emotion-2-line:before {
  content: "\EC8A"
}

.ri-emotion-fill:before {
  content: "\EC8B"
}

.ri-emotion-happy-fill:before {
  content: "\EC8C"
}

.ri-emotion-happy-line:before {
  content: "\EC8D"
}

.ri-emotion-laugh-fill:before {
  content: "\EC8E"
}

.ri-emotion-laugh-line:before {
  content: "\EC8F"
}

.ri-emotion-line:before {
  content: "\EC90"
}

.ri-emotion-normal-fill:before {
  content: "\EC91"
}

.ri-emotion-normal-line:before {
  content: "\EC92"
}

.ri-emotion-sad-fill:before {
  content: "\EC93"
}

.ri-emotion-sad-line:before {
  content: "\EC94"
}

.ri-emotion-unhappy-fill:before {
  content: "\EC95"
}

.ri-emotion-unhappy-line:before {
  content: "\EC96"
}

.ri-empathize-fill:before {
  content: "\EC97"
}

.ri-empathize-line:before {
  content: "\EC98"
}

.ri-emphasis-cn:before {
  content: "\EC99"
}

.ri-emphasis:before {
  content: "\EC9A"
}

.ri-english-input:before {
  content: "\EC9B"
}

.ri-equalizer-fill:before {
  content: "\EC9C"
}

.ri-equalizer-line:before {
  content: "\EC9D"
}

.ri-eraser-fill:before {
  content: "\EC9E"
}

.ri-eraser-line:before {
  content: "\EC9F"
}

.ri-error-warning-fill:before {
  content: "\ECA0"
}

.ri-error-warning-line:before {
  content: "\ECA1"
}

.ri-evernote-fill:before {
  content: "\ECA2"
}

.ri-evernote-line:before {
  content: "\ECA3"
}

.ri-exchange-box-fill:before {
  content: "\ECA4"
}

.ri-exchange-box-line:before {
  content: "\ECA5"
}

.ri-exchange-cny-fill:before {
  content: "\ECA6"
}

.ri-exchange-cny-line:before {
  content: "\ECA7"
}

.ri-exchange-dollar-fill:before {
  content: "\ECA8"
}

.ri-exchange-dollar-line:before {
  content: "\ECA9"
}

.ri-exchange-fill:before {
  content: "\ECAA"
}

.ri-exchange-funds-fill:before {
  content: "\ECAB"
}

.ri-exchange-funds-line:before {
  content: "\ECAC"
}

.ri-exchange-line:before {
  content: "\ECAD"
}

.ri-external-link-fill:before {
  content: "\ECAE"
}

.ri-external-link-line:before {
  content: "\ECAF"
}

.ri-eye-2-fill:before {
  content: "\ECB0"
}

.ri-eye-2-line:before {
  content: "\ECB1"
}

.ri-eye-close-fill:before {
  content: "\ECB2"
}

.ri-eye-close-line:before {
  content: "\ECB3"
}

.ri-eye-fill:before {
  content: "\ECB4"
}

.ri-eye-line:before {
  content: "\ECB5"
}

.ri-eye-off-fill:before {
  content: "\ECB6"
}

.ri-eye-off-line:before {
  content: "\ECB7"
}

.ri-facebook-box-fill:before {
  content: "\ECB8"
}

.ri-facebook-box-line:before {
  content: "\ECB9"
}

.ri-facebook-circle-fill:before {
  content: "\ECBA"
}

.ri-facebook-circle-line:before {
  content: "\ECBB"
}

.ri-facebook-fill:before {
  content: "\ECBC"
}

.ri-facebook-line:before {
  content: "\ECBD"
}

.ri-fahrenheit-fill:before {
  content: "\ECBE"
}

.ri-fahrenheit-line:before {
  content: "\ECBF"
}

.ri-feedback-fill:before {
  content: "\ECC0"
}

.ri-feedback-line:before {
  content: "\ECC1"
}

.ri-file-2-fill:before {
  content: "\ECC2"
}

.ri-file-2-line:before {
  content: "\ECC3"
}

.ri-file-3-fill:before {
  content: "\ECC4"
}

.ri-file-3-line:before {
  content: "\ECC5"
}

.ri-file-4-fill:before {
  content: "\ECC6"
}

.ri-file-4-line:before {
  content: "\ECC7"
}

.ri-file-add-fill:before {
  content: "\ECC8"
}

.ri-file-add-line:before {
  content: "\ECC9"
}

.ri-file-chart-2-fill:before {
  content: "\ECCA"
}

.ri-file-chart-2-line:before {
  content: "\ECCB"
}

.ri-file-chart-fill:before {
  content: "\ECCC"
}

.ri-file-chart-line:before {
  content: "\ECCD"
}

.ri-file-cloud-fill:before {
  content: "\ECCE"
}

.ri-file-cloud-line:before {
  content: "\ECCF"
}

.ri-file-code-fill:before {
  content: "\ECD0"
}

.ri-file-code-line:before {
  content: "\ECD1"
}

.ri-file-copy-2-fill:before {
  content: "\ECD2"
}

.ri-file-copy-2-line:before {
  content: "\ECD3"
}

.ri-file-copy-fill:before {
  content: "\ECD4"
}

.ri-file-copy-line:before {
  content: "\ECD5"
}

.ri-file-damage-fill:before {
  content: "\ECD6"
}

.ri-file-damage-line:before {
  content: "\ECD7"
}

.ri-file-download-fill:before {
  content: "\ECD8"
}

.ri-file-download-line:before {
  content: "\ECD9"
}

.ri-file-edit-fill:before {
  content: "\ECDA"
}

.ri-file-edit-line:before {
  content: "\ECDB"
}

.ri-file-excel-2-fill:before {
  content: "\ECDC"
}

.ri-file-excel-2-line:before {
  content: "\ECDD"
}

.ri-file-excel-fill:before {
  content: "\ECDE"
}

.ri-file-excel-line:before {
  content: "\ECDF"
}

.ri-file-fill:before {
  content: "\ECE0"
}

.ri-file-forbid-fill:before {
  content: "\ECE1"
}

.ri-file-forbid-line:before {
  content: "\ECE2"
}

.ri-file-gif-fill:before {
  content: "\ECE3"
}

.ri-file-gif-line:before {
  content: "\ECE4"
}

.ri-file-history-fill:before {
  content: "\ECE5"
}

.ri-file-history-line:before {
  content: "\ECE6"
}

.ri-file-hwp-fill:before {
  content: "\ECE7"
}

.ri-file-hwp-line:before {
  content: "\ECE8"
}

.ri-file-info-fill:before {
  content: "\ECE9"
}

.ri-file-info-line:before {
  content: "\ECEA"
}

.ri-file-line:before {
  content: "\ECEB"
}

.ri-file-list-2-fill:before {
  content: "\ECEC"
}

.ri-file-list-2-line:before {
  content: "\ECED"
}

.ri-file-list-3-fill:before {
  content: "\ECEE"
}

.ri-file-list-3-line:before {
  content: "\ECEF"
}

.ri-file-list-fill:before {
  content: "\ECF0"
}

.ri-file-list-line:before {
  content: "\ECF1"
}

.ri-file-lock-fill:before {
  content: "\ECF2"
}

.ri-file-lock-line:before {
  content: "\ECF3"
}

.ri-file-mark-fill:before {
  content: "\ECF4"
}

.ri-file-mark-line:before {
  content: "\ECF5"
}

.ri-file-music-fill:before {
  content: "\ECF6"
}

.ri-file-music-line:before {
  content: "\ECF7"
}

.ri-file-paper-2-fill:before {
  content: "\ECF8"
}

.ri-file-paper-2-line:before {
  content: "\ECF9"
}

.ri-file-paper-fill:before {
  content: "\ECFA"
}

.ri-file-paper-line:before {
  content: "\ECFB"
}

.ri-file-pdf-fill:before {
  content: "\ECFC"
}

.ri-file-pdf-line:before {
  content: "\ECFD"
}

.ri-file-ppt-2-fill:before {
  content: "\ECFE"
}

.ri-file-ppt-2-line:before {
  content: "\ECFF"
}

.ri-file-ppt-fill:before {
  content: "\ED00"
}

.ri-file-ppt-line:before {
  content: "\ED01"
}

.ri-file-reduce-fill:before {
  content: "\ED02"
}

.ri-file-reduce-line:before {
  content: "\ED03"
}

.ri-file-search-fill:before {
  content: "\ED04"
}

.ri-file-search-line:before {
  content: "\ED05"
}

.ri-file-settings-fill:before {
  content: "\ED06"
}

.ri-file-settings-line:before {
  content: "\ED07"
}

.ri-file-shield-2-fill:before {
  content: "\ED08"
}

.ri-file-shield-2-line:before {
  content: "\ED09"
}

.ri-file-shield-fill:before {
  content: "\ED0A"
}

.ri-file-shield-line:before {
  content: "\ED0B"
}

.ri-file-shred-fill:before {
  content: "\ED0C"
}

.ri-file-shred-line:before {
  content: "\ED0D"
}

.ri-file-text-fill:before {
  content: "\ED0E"
}

.ri-file-text-line:before {
  content: "\ED0F"
}

.ri-file-transfer-fill:before {
  content: "\ED10"
}

.ri-file-transfer-line:before {
  content: "\ED11"
}

.ri-file-unknow-fill:before {
  content: "\ED12"
}

.ri-file-unknow-line:before {
  content: "\ED13"
}

.ri-file-upload-fill:before {
  content: "\ED14"
}

.ri-file-upload-line:before {
  content: "\ED15"
}

.ri-file-user-fill:before {
  content: "\ED16"
}

.ri-file-user-line:before {
  content: "\ED17"
}

.ri-file-warning-fill:before {
  content: "\ED18"
}

.ri-file-warning-line:before {
  content: "\ED19"
}

.ri-file-word-2-fill:before {
  content: "\ED1A"
}

.ri-file-word-2-line:before {
  content: "\ED1B"
}

.ri-file-word-fill:before {
  content: "\ED1C"
}

.ri-file-word-line:before {
  content: "\ED1D"
}

.ri-file-zip-fill:before {
  content: "\ED1E"
}

.ri-file-zip-line:before {
  content: "\ED1F"
}

.ri-film-fill:before {
  content: "\ED20"
}

.ri-film-line:before {
  content: "\ED21"
}

.ri-filter-2-fill:before {
  content: "\ED22"
}

.ri-filter-2-line:before {
  content: "\ED23"
}

.ri-filter-3-fill:before {
  content: "\ED24"
}

.ri-filter-3-line:before {
  content: "\ED25"
}

.ri-filter-fill:before {
  content: "\ED26"
}

.ri-filter-line:before {
  content: "\ED27"
}

.ri-filter-off-fill:before {
  content: "\ED28"
}

.ri-filter-off-line:before {
  content: "\ED29"
}

.ri-find-replace-fill:before {
  content: "\ED2A"
}

.ri-find-replace-line:before {
  content: "\ED2B"
}

.ri-finder-fill:before {
  content: "\ED2C"
}

.ri-finder-line:before {
  content: "\ED2D"
}

.ri-fingerprint-2-fill:before {
  content: "\ED2E"
}

.ri-fingerprint-2-line:before {
  content: "\ED2F"
}

.ri-fingerprint-fill:before {
  content: "\ED30"
}

.ri-fingerprint-line:before {
  content: "\ED31"
}

.ri-fire-fill:before {
  content: "\ED32"
}

.ri-fire-line:before {
  content: "\ED33"
}

.ri-firefox-fill:before {
  content: "\ED34"
}

.ri-firefox-line:before {
  content: "\ED35"
}

.ri-first-aid-kit-fill:before {
  content: "\ED36"
}

.ri-first-aid-kit-line:before {
  content: "\ED37"
}

.ri-flag-2-fill:before {
  content: "\ED38"
}

.ri-flag-2-line:before {
  content: "\ED39"
}

.ri-flag-fill:before {
  content: "\ED3A"
}

.ri-flag-line:before {
  content: "\ED3B"
}

.ri-flashlight-fill:before {
  content: "\ED3C"
}

.ri-flashlight-line:before {
  content: "\ED3D"
}

.ri-flask-fill:before {
  content: "\ED3E"
}

.ri-flask-line:before {
  content: "\ED3F"
}

.ri-flight-land-fill:before {
  content: "\ED40"
}

.ri-flight-land-line:before {
  content: "\ED41"
}

.ri-flight-takeoff-fill:before {
  content: "\ED42"
}

.ri-flight-takeoff-line:before {
  content: "\ED43"
}

.ri-flood-fill:before {
  content: "\ED44"
}

.ri-flood-line:before {
  content: "\ED45"
}

.ri-flow-chart:before {
  content: "\ED46"
}

.ri-flutter-fill:before {
  content: "\ED47"
}

.ri-flutter-line:before {
  content: "\ED48"
}

.ri-focus-2-fill:before {
  content: "\ED49"
}

.ri-focus-2-line:before {
  content: "\ED4A"
}

.ri-focus-3-fill:before {
  content: "\ED4B"
}

.ri-focus-3-line:before {
  content: "\ED4C"
}

.ri-focus-fill:before {
  content: "\ED4D"
}

.ri-focus-line:before {
  content: "\ED4E"
}

.ri-foggy-fill:before {
  content: "\ED4F"
}

.ri-foggy-line:before {
  content: "\ED50"
}

.ri-folder-2-fill:before {
  content: "\ED51"
}

.ri-folder-2-line:before {
  content: "\ED52"
}

.ri-folder-3-fill:before {
  content: "\ED53"
}

.ri-folder-3-line:before {
  content: "\ED54"
}

.ri-folder-4-fill:before {
  content: "\ED55"
}

.ri-folder-4-line:before {
  content: "\ED56"
}

.ri-folder-5-fill:before {
  content: "\ED57"
}

.ri-folder-5-line:before {
  content: "\ED58"
}

.ri-folder-add-fill:before {
  content: "\ED59"
}

.ri-folder-add-line:before {
  content: "\ED5A"
}

.ri-folder-chart-2-fill:before {
  content: "\ED5B"
}

.ri-folder-chart-2-line:before {
  content: "\ED5C"
}

.ri-folder-chart-fill:before {
  content: "\ED5D"
}

.ri-folder-chart-line:before {
  content: "\ED5E"
}

.ri-folder-download-fill:before {
  content: "\ED5F"
}

.ri-folder-download-line:before {
  content: "\ED60"
}

.ri-folder-fill:before {
  content: "\ED61"
}

.ri-folder-forbid-fill:before {
  content: "\ED62"
}

.ri-folder-forbid-line:before {
  content: "\ED63"
}

.ri-folder-history-fill:before {
  content: "\ED64"
}

.ri-folder-history-line:before {
  content: "\ED65"
}

.ri-folder-info-fill:before {
  content: "\ED66"
}

.ri-folder-info-line:before {
  content: "\ED67"
}

.ri-folder-keyhole-fill:before {
  content: "\ED68"
}

.ri-folder-keyhole-line:before {
  content: "\ED69"
}

.ri-folder-line:before {
  content: "\ED6A"
}

.ri-folder-lock-fill:before {
  content: "\ED6B"
}

.ri-folder-lock-line:before {
  content: "\ED6C"
}

.ri-folder-music-fill:before {
  content: "\ED6D"
}

.ri-folder-music-line:before {
  content: "\ED6E"
}

.ri-folder-open-fill:before {
  content: "\ED6F"
}

.ri-folder-open-line:before {
  content: "\ED70"
}

.ri-folder-received-fill:before {
  content: "\ED71"
}

.ri-folder-received-line:before {
  content: "\ED72"
}

.ri-folder-reduce-fill:before {
  content: "\ED73"
}

.ri-folder-reduce-line:before {
  content: "\ED74"
}

.ri-folder-settings-fill:before {
  content: "\ED75"
}

.ri-folder-settings-line:before {
  content: "\ED76"
}

.ri-folder-shared-fill:before {
  content: "\ED77"
}

.ri-folder-shared-line:before {
  content: "\ED78"
}

.ri-folder-shield-2-fill:before {
  content: "\ED79"
}

.ri-folder-shield-2-line:before {
  content: "\ED7A"
}

.ri-folder-shield-fill:before {
  content: "\ED7B"
}

.ri-folder-shield-line:before {
  content: "\ED7C"
}

.ri-folder-transfer-fill:before {
  content: "\ED7D"
}

.ri-folder-transfer-line:before {
  content: "\ED7E"
}

.ri-folder-unknow-fill:before {
  content: "\ED7F"
}

.ri-folder-unknow-line:before {
  content: "\ED80"
}

.ri-folder-upload-fill:before {
  content: "\ED81"
}

.ri-folder-upload-line:before {
  content: "\ED82"
}

.ri-folder-user-fill:before {
  content: "\ED83"
}

.ri-folder-user-line:before {
  content: "\ED84"
}

.ri-folder-warning-fill:before {
  content: "\ED85"
}

.ri-folder-warning-line:before {
  content: "\ED86"
}

.ri-folder-zip-fill:before {
  content: "\ED87"
}

.ri-folder-zip-line:before {
  content: "\ED88"
}

.ri-folders-fill:before {
  content: "\ED89"
}

.ri-folders-line:before {
  content: "\ED8A"
}

.ri-font-color:before {
  content: "\ED8B"
}

.ri-font-size-2:before {
  content: "\ED8C"
}

.ri-font-size:before {
  content: "\ED8D"
}

.ri-football-fill:before {
  content: "\ED8E"
}

.ri-football-line:before {
  content: "\ED8F"
}

.ri-footprint-fill:before {
  content: "\ED90"
}

.ri-footprint-line:before {
  content: "\ED91"
}

.ri-forbid-2-fill:before {
  content: "\ED92"
}

.ri-forbid-2-line:before {
  content: "\ED93"
}

.ri-forbid-fill:before {
  content: "\ED94"
}

.ri-forbid-line:before {
  content: "\ED95"
}

.ri-format-clear:before {
  content: "\ED96"
}

.ri-fridge-fill:before {
  content: "\ED97"
}

.ri-fridge-line:before {
  content: "\ED98"
}

.ri-fullscreen-exit-fill:before {
  content: "\ED99"
}

.ri-fullscreen-exit-line:before {
  content: "\ED9A"
}

.ri-fullscreen-fill:before {
  content: "\ED9B"
}

.ri-fullscreen-line:before {
  content: "\ED9C"
}

.ri-function-fill:before {
  content: "\ED9D"
}

.ri-function-line:before {
  content: "\ED9E"
}

.ri-functions:before {
  content: "\ED9F"
}

.ri-funds-box-fill:before {
  content: "\EDA0"
}

.ri-funds-box-line:before {
  content: "\EDA1"
}

.ri-funds-fill:before {
  content: "\EDA2"
}

.ri-funds-line:before {
  content: "\EDA3"
}

.ri-gallery-fill:before {
  content: "\EDA4"
}

.ri-gallery-line:before {
  content: "\EDA5"
}

.ri-gallery-upload-fill:before {
  content: "\EDA6"
}

.ri-gallery-upload-line:before {
  content: "\EDA7"
}

.ri-game-fill:before {
  content: "\EDA8"
}

.ri-game-line:before {
  content: "\EDA9"
}

.ri-gamepad-fill:before {
  content: "\EDAA"
}

.ri-gamepad-line:before {
  content: "\EDAB"
}

.ri-gas-station-fill:before {
  content: "\EDAC"
}

.ri-gas-station-line:before {
  content: "\EDAD"
}

.ri-gatsby-fill:before {
  content: "\EDAE"
}

.ri-gatsby-line:before {
  content: "\EDAF"
}

.ri-genderless-fill:before {
  content: "\EDB0"
}

.ri-genderless-line:before {
  content: "\EDB1"
}

.ri-ghost-2-fill:before {
  content: "\EDB2"
}

.ri-ghost-2-line:before {
  content: "\EDB3"
}

.ri-ghost-fill:before {
  content: "\EDB4"
}

.ri-ghost-line:before {
  content: "\EDB5"
}

.ri-ghost-smile-fill:before {
  content: "\EDB6"
}

.ri-ghost-smile-line:before {
  content: "\EDB7"
}

.ri-gift-2-fill:before {
  content: "\EDB8"
}

.ri-gift-2-line:before {
  content: "\EDB9"
}

.ri-gift-fill:before {
  content: "\EDBA"
}

.ri-gift-line:before {
  content: "\EDBB"
}

.ri-git-branch-fill:before {
  content: "\EDBC"
}

.ri-git-branch-line:before {
  content: "\EDBD"
}

.ri-git-commit-fill:before {
  content: "\EDBE"
}

.ri-git-commit-line:before {
  content: "\EDBF"
}

.ri-git-merge-fill:before {
  content: "\EDC0"
}

.ri-git-merge-line:before {
  content: "\EDC1"
}

.ri-git-pull-request-fill:before {
  content: "\EDC2"
}

.ri-git-pull-request-line:before {
  content: "\EDC3"
}

.ri-git-repository-commits-fill:before {
  content: "\EDC4"
}

.ri-git-repository-commits-line:before {
  content: "\EDC5"
}

.ri-git-repository-fill:before {
  content: "\EDC6"
}

.ri-git-repository-line:before {
  content: "\EDC7"
}

.ri-git-repository-private-fill:before {
  content: "\EDC8"
}

.ri-git-repository-private-line:before {
  content: "\EDC9"
}

.ri-github-fill:before {
  content: "\EDCA"
}

.ri-github-line:before {
  content: "\EDCB"
}

.ri-gitlab-fill:before {
  content: "\EDCC"
}

.ri-gitlab-line:before {
  content: "\EDCD"
}

.ri-global-fill:before {
  content: "\EDCE"
}

.ri-global-line:before {
  content: "\EDCF"
}

.ri-globe-fill:before {
  content: "\EDD0"
}

.ri-globe-line:before {
  content: "\EDD1"
}

.ri-goblet-fill:before {
  content: "\EDD2"
}

.ri-goblet-line:before {
  content: "\EDD3"
}

.ri-google-fill:before {
  content: "\EDD4"
}

.ri-google-line:before {
  content: "\EDD5"
}

.ri-google-play-fill:before {
  content: "\EDD6"
}

.ri-google-play-line:before {
  content: "\EDD7"
}

.ri-government-fill:before {
  content: "\EDD8"
}

.ri-government-line:before {
  content: "\EDD9"
}

.ri-gps-fill:before {
  content: "\EDDA"
}

.ri-gps-line:before {
  content: "\EDDB"
}

.ri-gradienter-fill:before {
  content: "\EDDC"
}

.ri-gradienter-line:before {
  content: "\EDDD"
}

.ri-grid-fill:before {
  content: "\EDDE"
}

.ri-grid-line:before {
  content: "\EDDF"
}

.ri-group-2-fill:before {
  content: "\EDE0"
}

.ri-group-2-line:before {
  content: "\EDE1"
}

.ri-group-fill:before {
  content: "\EDE2"
}

.ri-group-line:before {
  content: "\EDE3"
}

.ri-guide-fill:before {
  content: "\EDE4"
}

.ri-guide-line:before {
  content: "\EDE5"
}

.ri-h-1:before {
  content: "\EDE6"
}

.ri-h-2:before {
  content: "\EDE7"
}

.ri-h-3:before {
  content: "\EDE8"
}

.ri-h-4:before {
  content: "\EDE9"
}

.ri-h-5:before {
  content: "\EDEA"
}

.ri-h-6:before {
  content: "\EDEB"
}

.ri-hail-fill:before {
  content: "\EDEC"
}

.ri-hail-line:before {
  content: "\EDED"
}

.ri-hammer-fill:before {
  content: "\EDEE"
}

.ri-hammer-line:before {
  content: "\EDEF"
}

.ri-hand-coin-fill:before {
  content: "\EDF0"
}

.ri-hand-coin-line:before {
  content: "\EDF1"
}

.ri-hand-heart-fill:before {
  content: "\EDF2"
}

.ri-hand-heart-line:before {
  content: "\EDF3"
}

.ri-hand-sanitizer-fill:before {
  content: "\EDF4"
}

.ri-hand-sanitizer-line:before {
  content: "\EDF5"
}

.ri-handbag-fill:before {
  content: "\EDF6"
}

.ri-handbag-line:before {
  content: "\EDF7"
}

.ri-hard-drive-2-fill:before {
  content: "\EDF8"
}

.ri-hard-drive-2-line:before {
  content: "\EDF9"
}

.ri-hard-drive-fill:before {
  content: "\EDFA"
}

.ri-hard-drive-line:before {
  content: "\EDFB"
}

.ri-hashtag:before {
  content: "\EDFC"
}

.ri-haze-2-fill:before {
  content: "\EDFD"
}

.ri-haze-2-line:before {
  content: "\EDFE"
}

.ri-haze-fill:before {
  content: "\EDFF"
}

.ri-haze-line:before {
  content: "\EE00"
}

.ri-hd-fill:before {
  content: "\EE01"
}

.ri-hd-line:before {
  content: "\EE02"
}

.ri-heading:before {
  content: "\EE03"
}

.ri-headphone-fill:before {
  content: "\EE04"
}

.ri-headphone-line:before {
  content: "\EE05"
}

.ri-health-book-fill:before {
  content: "\EE06"
}

.ri-health-book-line:before {
  content: "\EE07"
}

.ri-heart-2-fill:before {
  content: "\EE08"
}

.ri-heart-2-line:before {
  content: "\EE09"
}

.ri-heart-3-fill:before {
  content: "\EE0A"
}

.ri-heart-3-line:before {
  content: "\EE0B"
}

.ri-heart-add-fill:before {
  content: "\EE0C"
}

.ri-heart-add-line:before {
  content: "\EE0D"
}

.ri-heart-fill:before {
  content: "\EE0E"
}

.ri-heart-line:before {
  content: "\EE0F"
}

.ri-heart-pulse-fill:before {
  content: "\EE10"
}

.ri-heart-pulse-line:before {
  content: "\EE11"
}

.ri-hearts-fill:before {
  content: "\EE12"
}

.ri-hearts-line:before {
  content: "\EE13"
}

.ri-heavy-showers-fill:before {
  content: "\EE14"
}

.ri-heavy-showers-line:before {
  content: "\EE15"
}

.ri-history-fill:before {
  content: "\EE16"
}

.ri-history-line:before {
  content: "\EE17"
}

.ri-home-2-fill:before {
  content: "\EE18"
}

.ri-home-2-line:before {
  content: "\EE19"
}

.ri-home-3-fill:before {
  content: "\EE1A"
}

.ri-home-3-line:before {
  content: "\EE1B"
}

.ri-home-4-fill:before {
  content: "\EE1C"
}

.ri-home-4-line:before {
  content: "\EE1D"
}

.ri-home-5-fill:before {
  content: "\EE1E"
}

.ri-home-5-line:before {
  content: "\EE1F"
}

.ri-home-6-fill:before {
  content: "\EE20"
}

.ri-home-6-line:before {
  content: "\EE21"
}

.ri-home-7-fill:before {
  content: "\EE22"
}

.ri-home-7-line:before {
  content: "\EE23"
}

.ri-home-8-fill:before {
  content: "\EE24"
}

.ri-home-8-line:before {
  content: "\EE25"
}

.ri-home-fill:before {
  content: "\EE26"
}

.ri-home-gear-fill:before {
  content: "\EE27"
}

.ri-home-gear-line:before {
  content: "\EE28"
}

.ri-home-heart-fill:before {
  content: "\EE29"
}

.ri-home-heart-line:before {
  content: "\EE2A"
}

.ri-home-line:before {
  content: "\EE2B"
}

.ri-home-smile-2-fill:before {
  content: "\EE2C"
}

.ri-home-smile-2-line:before {
  content: "\EE2D"
}

.ri-home-smile-fill:before {
  content: "\EE2E"
}

.ri-home-smile-line:before {
  content: "\EE2F"
}

.ri-home-wifi-fill:before {
  content: "\EE30"
}

.ri-home-wifi-line:before {
  content: "\EE31"
}

.ri-honor-of-kings-fill:before {
  content: "\EE32"
}

.ri-honor-of-kings-line:before {
  content: "\EE33"
}

.ri-honour-fill:before {
  content: "\EE34"
}

.ri-honour-line:before {
  content: "\EE35"
}

.ri-hospital-fill:before {
  content: "\EE36"
}

.ri-hospital-line:before {
  content: "\EE37"
}

.ri-hotel-bed-fill:before {
  content: "\EE38"
}

.ri-hotel-bed-line:before {
  content: "\EE39"
}

.ri-hotel-fill:before {
  content: "\EE3A"
}

.ri-hotel-line:before {
  content: "\EE3B"
}

.ri-hotspot-fill:before {
  content: "\EE3C"
}

.ri-hotspot-line:before {
  content: "\EE3D"
}

.ri-hq-fill:before {
  content: "\EE3E"
}

.ri-hq-line:before {
  content: "\EE3F"
}

.ri-html5-fill:before {
  content: "\EE40"
}

.ri-html5-line:before {
  content: "\EE41"
}

.ri-ie-fill:before {
  content: "\EE42"
}

.ri-ie-line:before {
  content: "\EE43"
}

.ri-image-2-fill:before {
  content: "\EE44"
}

.ri-image-2-line:before {
  content: "\EE45"
}

.ri-image-add-fill:before {
  content: "\EE46"
}

.ri-image-add-line:before {
  content: "\EE47"
}

.ri-image-edit-fill:before {
  content: "\EE48"
}

.ri-image-edit-line:before {
  content: "\EE49"
}

.ri-image-fill:before {
  content: "\EE4A"
}

.ri-image-line:before {
  content: "\EE4B"
}

.ri-inbox-archive-fill:before {
  content: "\EE4C"
}

.ri-inbox-archive-line:before {
  content: "\EE4D"
}

.ri-inbox-fill:before {
  content: "\EE4E"
}

.ri-inbox-line:before {
  content: "\EE4F"
}

.ri-inbox-unarchive-fill:before {
  content: "\EE50"
}

.ri-inbox-unarchive-line:before {
  content: "\EE51"
}

.ri-increase-decrease-fill:before {
  content: "\EE52"
}

.ri-increase-decrease-line:before {
  content: "\EE53"
}

.ri-indent-decrease:before {
  content: "\EE54"
}

.ri-indent-increase:before {
  content: "\EE55"
}

.ri-indeterminate-circle-fill:before {
  content: "\EE56"
}

.ri-indeterminate-circle-line:before {
  content: "\EE57"
}

.ri-information-fill:before {
  content: "\EE58"
}

.ri-information-line:before {
  content: "\EE59"
}

.ri-infrared-thermometer-fill:before {
  content: "\EE5A"
}

.ri-infrared-thermometer-line:before {
  content: "\EE5B"
}

.ri-ink-bottle-fill:before {
  content: "\EE5C"
}

.ri-ink-bottle-line:before {
  content: "\EE5D"
}

.ri-input-cursor-move:before {
  content: "\EE5E"
}

.ri-input-method-fill:before {
  content: "\EE5F"
}

.ri-input-method-line:before {
  content: "\EE60"
}

.ri-insert-column-left:before {
  content: "\EE61"
}

.ri-insert-column-right:before {
  content: "\EE62"
}

.ri-insert-row-bottom:before {
  content: "\EE63"
}

.ri-insert-row-top:before {
  content: "\EE64"
}

.ri-instagram-fill:before {
  content: "\EE65"
}

.ri-instagram-line:before {
  content: "\EE66"
}

.ri-install-fill:before {
  content: "\EE67"
}

.ri-install-line:before {
  content: "\EE68"
}

.ri-invision-fill:before {
  content: "\EE69"
}

.ri-invision-line:before {
  content: "\EE6A"
}

.ri-italic:before {
  content: "\EE6B"
}

.ri-kakao-talk-fill:before {
  content: "\EE6C"
}

.ri-kakao-talk-line:before {
  content: "\EE6D"
}

.ri-key-2-fill:before {
  content: "\EE6E"
}

.ri-key-2-line:before {
  content: "\EE6F"
}

.ri-key-fill:before {
  content: "\EE70"
}

.ri-key-line:before {
  content: "\EE71"
}

.ri-keyboard-box-fill:before {
  content: "\EE72"
}

.ri-keyboard-box-line:before {
  content: "\EE73"
}

.ri-keyboard-fill:before {
  content: "\EE74"
}

.ri-keyboard-line:before {
  content: "\EE75"
}

.ri-keynote-fill:before {
  content: "\EE76"
}

.ri-keynote-line:before {
  content: "\EE77"
}

.ri-knife-blood-fill:before {
  content: "\EE78"
}

.ri-knife-blood-line:before {
  content: "\EE79"
}

.ri-knife-fill:before {
  content: "\EE7A"
}

.ri-knife-line:before {
  content: "\EE7B"
}

.ri-landscape-fill:before {
  content: "\EE7C"
}

.ri-landscape-line:before {
  content: "\EE7D"
}

.ri-layout-2-fill:before {
  content: "\EE7E"
}

.ri-layout-2-line:before {
  content: "\EE7F"
}

.ri-layout-3-fill:before {
  content: "\EE80"
}

.ri-layout-3-line:before {
  content: "\EE81"
}

.ri-layout-4-fill:before {
  content: "\EE82"
}

.ri-layout-4-line:before {
  content: "\EE83"
}

.ri-layout-5-fill:before {
  content: "\EE84"
}

.ri-layout-5-line:before {
  content: "\EE85"
}

.ri-layout-6-fill:before {
  content: "\EE86"
}

.ri-layout-6-line:before {
  content: "\EE87"
}

.ri-layout-bottom-2-fill:before {
  content: "\EE88"
}

.ri-layout-bottom-2-line:before {
  content: "\EE89"
}

.ri-layout-bottom-fill:before {
  content: "\EE8A"
}

.ri-layout-bottom-line:before {
  content: "\EE8B"
}

.ri-layout-column-fill:before {
  content: "\EE8C"
}

.ri-layout-column-line:before {
  content: "\EE8D"
}

.ri-layout-fill:before {
  content: "\EE8E"
}

.ri-layout-grid-fill:before {
  content: "\EE8F"
}

.ri-layout-grid-line:before {
  content: "\EE90"
}

.ri-layout-left-2-fill:before {
  content: "\EE91"
}

.ri-layout-left-2-line:before {
  content: "\EE92"
}

.ri-layout-left-fill:before {
  content: "\EE93"
}

.ri-layout-left-line:before {
  content: "\EE94"
}

.ri-layout-line:before {
  content: "\EE95"
}

.ri-layout-masonry-fill:before {
  content: "\EE96"
}

.ri-layout-masonry-line:before {
  content: "\EE97"
}

.ri-layout-right-2-fill:before {
  content: "\EE98"
}

.ri-layout-right-2-line:before {
  content: "\EE99"
}

.ri-layout-right-fill:before {
  content: "\EE9A"
}

.ri-layout-right-line:before {
  content: "\EE9B"
}

.ri-layout-row-fill:before {
  content: "\EE9C"
}

.ri-layout-row-line:before {
  content: "\EE9D"
}

.ri-layout-top-2-fill:before {
  content: "\EE9E"
}

.ri-layout-top-2-line:before {
  content: "\EE9F"
}

.ri-layout-top-fill:before {
  content: "\EEA0"
}

.ri-layout-top-line:before {
  content: "\EEA1"
}

.ri-leaf-fill:before {
  content: "\EEA2"
}

.ri-leaf-line:before {
  content: "\EEA3"
}

.ri-lifebuoy-fill:before {
  content: "\EEA4"
}

.ri-lifebuoy-line:before {
  content: "\EEA5"
}

.ri-lightbulb-fill:before {
  content: "\EEA6"
}

.ri-lightbulb-flash-fill:before {
  content: "\EEA7"
}

.ri-lightbulb-flash-line:before {
  content: "\EEA8"
}

.ri-lightbulb-line:before {
  content: "\EEA9"
}

.ri-line-chart-fill:before {
  content: "\EEAA"
}

.ri-line-chart-line:before {
  content: "\EEAB"
}

.ri-line-fill:before {
  content: "\EEAC"
}

.ri-line-height:before {
  content: "\EEAD"
}

.ri-line-line:before {
  content: "\EEAE"
}

.ri-link-m:before {
  content: "\EEAF"
}

.ri-link-unlink-m:before {
  content: "\EEB0"
}

.ri-link-unlink:before {
  content: "\EEB1"
}

.ri-link:before {
  content: "\EEB2"
}

.ri-linkedin-box-fill:before {
  content: "\EEB3"
}

.ri-linkedin-box-line:before {
  content: "\EEB4"
}

.ri-linkedin-fill:before {
  content: "\EEB5"
}

.ri-linkedin-line:before {
  content: "\EEB6"
}

.ri-links-fill:before {
  content: "\EEB7"
}

.ri-links-line:before {
  content: "\EEB8"
}

.ri-list-check-2:before {
  content: "\EEB9"
}

.ri-list-check:before {
  content: "\EEBA"
}

.ri-list-ordered:before {
  content: "\EEBB"
}

.ri-list-settings-fill:before {
  content: "\EEBC"
}

.ri-list-settings-line:before {
  content: "\EEBD"
}

.ri-list-unordered:before {
  content: "\EEBE"
}

.ri-live-fill:before {
  content: "\EEBF"
}

.ri-live-line:before {
  content: "\EEC0"
}

.ri-loader-2-fill:before {
  content: "\EEC1"
}

.ri-loader-2-line:before {
  content: "\EEC2"
}

.ri-loader-3-fill:before {
  content: "\EEC3"
}

.ri-loader-3-line:before {
  content: "\EEC4"
}

.ri-loader-4-fill:before {
  content: "\EEC5"
}

.ri-loader-4-line:before {
  content: "\EEC6"
}

.ri-loader-5-fill:before {
  content: "\EEC7"
}

.ri-loader-5-line:before {
  content: "\EEC8"
}

.ri-loader-fill:before {
  content: "\EEC9"
}

.ri-loader-line:before {
  content: "\EECA"
}

.ri-lock-2-fill:before {
  content: "\EECB"
}

.ri-lock-2-line:before {
  content: "\EECC"
}

.ri-lock-fill:before {
  content: "\EECD"
}

.ri-lock-line:before {
  content: "\EECE"
}

.ri-lock-password-fill:before {
  content: "\EECF"
}

.ri-lock-password-line:before {
  content: "\EED0"
}

.ri-lock-unlock-fill:before {
  content: "\EED1"
}

.ri-lock-unlock-line:before {
  content: "\EED2"
}

.ri-login-box-fill:before {
  content: "\EED3"
}

.ri-login-box-line:before {
  content: "\EED4"
}

.ri-login-circle-fill:before {
  content: "\EED5"
}

.ri-login-circle-line:before {
  content: "\EED6"
}

.ri-logout-box-fill:before {
  content: "\EED7"
}

.ri-logout-box-line:before {
  content: "\EED8"
}

.ri-logout-box-r-fill:before {
  content: "\EED9"
}

.ri-logout-box-r-line:before {
  content: "\EEDA"
}

.ri-logout-circle-fill:before {
  content: "\EEDB"
}

.ri-logout-circle-line:before {
  content: "\EEDC"
}

.ri-logout-circle-r-fill:before {
  content: "\EEDD"
}

.ri-logout-circle-r-line:before {
  content: "\EEDE"
}

.ri-luggage-cart-fill:before {
  content: "\EEDF"
}

.ri-luggage-cart-line:before {
  content: "\EEE0"
}

.ri-luggage-deposit-fill:before {
  content: "\EEE1"
}

.ri-luggage-deposit-line:before {
  content: "\EEE2"
}

.ri-lungs-fill:before {
  content: "\EEE3"
}

.ri-lungs-line:before {
  content: "\EEE4"
}

.ri-mac-fill:before {
  content: "\EEE5"
}

.ri-mac-line:before {
  content: "\EEE6"
}

.ri-macbook-fill:before {
  content: "\EEE7"
}

.ri-macbook-line:before {
  content: "\EEE8"
}

.ri-magic-fill:before {
  content: "\EEE9"
}

.ri-magic-line:before {
  content: "\EEEA"
}

.ri-mail-add-fill:before {
  content: "\EEEB"
}

.ri-mail-add-line:before {
  content: "\EEEC"
}

.ri-mail-check-fill:before {
  content: "\EEED"
}

.ri-mail-check-line:before {
  content: "\EEEE"
}

.ri-mail-close-fill:before {
  content: "\EEEF"
}

.ri-mail-close-line:before {
  content: "\EEF0"
}

.ri-mail-download-fill:before {
  content: "\EEF1"
}

.ri-mail-download-line:before {
  content: "\EEF2"
}

.ri-mail-fill:before {
  content: "\EEF3"
}

.ri-mail-forbid-fill:before {
  content: "\EEF4"
}

.ri-mail-forbid-line:before {
  content: "\EEF5"
}

.ri-mail-line:before {
  content: "\EEF6"
}

.ri-mail-lock-fill:before {
  content: "\EEF7"
}

.ri-mail-lock-line:before {
  content: "\EEF8"
}

.ri-mail-open-fill:before {
  content: "\EEF9"
}

.ri-mail-open-line:before {
  content: "\EEFA"
}

.ri-mail-send-fill:before {
  content: "\EEFB"
}

.ri-mail-send-line:before {
  content: "\EEFC"
}

.ri-mail-settings-fill:before {
  content: "\EEFD"
}

.ri-mail-settings-line:before {
  content: "\EEFE"
}

.ri-mail-star-fill:before {
  content: "\EEFF"
}

.ri-mail-star-line:before {
  content: "\EF00"
}

.ri-mail-unread-fill:before {
  content: "\EF01"
}

.ri-mail-unread-line:before {
  content: "\EF02"
}

.ri-mail-volume-fill:before {
  content: "\EF03"
}

.ri-mail-volume-line:before {
  content: "\EF04"
}

.ri-map-2-fill:before {
  content: "\EF05"
}

.ri-map-2-line:before {
  content: "\EF06"
}

.ri-map-fill:before {
  content: "\EF07"
}

.ri-map-line:before {
  content: "\EF08"
}

.ri-map-pin-2-fill:before {
  content: "\EF09"
}

.ri-map-pin-2-line:before {
  content: "\EF0A"
}

.ri-map-pin-3-fill:before {
  content: "\EF0B"
}

.ri-map-pin-3-line:before {
  content: "\EF0C"
}

.ri-map-pin-4-fill:before {
  content: "\EF0D"
}

.ri-map-pin-4-line:before {
  content: "\EF0E"
}

.ri-map-pin-5-fill:before {
  content: "\EF0F"
}

.ri-map-pin-5-line:before {
  content: "\EF10"
}

.ri-map-pin-add-fill:before {
  content: "\EF11"
}

.ri-map-pin-add-line:before {
  content: "\EF12"
}

.ri-map-pin-fill:before {
  content: "\EF13"
}

.ri-map-pin-line:before {
  content: "\EF14"
}

.ri-map-pin-range-fill:before {
  content: "\EF15"
}

.ri-map-pin-range-line:before {
  content: "\EF16"
}

.ri-map-pin-time-fill:before {
  content: "\EF17"
}

.ri-map-pin-time-line:before {
  content: "\EF18"
}

.ri-map-pin-user-fill:before {
  content: "\EF19"
}

.ri-map-pin-user-line:before {
  content: "\EF1A"
}

.ri-mark-pen-fill:before {
  content: "\EF1B"
}

.ri-mark-pen-line:before {
  content: "\EF1C"
}

.ri-markdown-fill:before {
  content: "\EF1D"
}

.ri-markdown-line:before {
  content: "\EF1E"
}

.ri-markup-fill:before {
  content: "\EF1F"
}

.ri-markup-line:before {
  content: "\EF20"
}

.ri-mastercard-fill:before {
  content: "\EF21"
}

.ri-mastercard-line:before {
  content: "\EF22"
}

.ri-mastodon-fill:before {
  content: "\EF23"
}

.ri-mastodon-line:before {
  content: "\EF24"
}

.ri-medal-2-fill:before {
  content: "\EF25"
}

.ri-medal-2-line:before {
  content: "\EF26"
}

.ri-medal-fill:before {
  content: "\EF27"
}

.ri-medal-line:before {
  content: "\EF28"
}

.ri-medicine-bottle-fill:before {
  content: "\EF29"
}

.ri-medicine-bottle-line:before {
  content: "\EF2A"
}

.ri-medium-fill:before {
  content: "\EF2B"
}

.ri-medium-line:before {
  content: "\EF2C"
}

.ri-men-fill:before {
  content: "\EF2D"
}

.ri-men-line:before {
  content: "\EF2E"
}

.ri-mental-health-fill:before {
  content: "\EF2F"
}

.ri-mental-health-line:before {
  content: "\EF30"
}

.ri-menu-2-fill:before {
  content: "\EF31"
}

.ri-menu-2-line:before {
  content: "\EF32"
}

.ri-menu-3-fill:before {
  content: "\EF33"
}

.ri-menu-3-line:before {
  content: "\EF34"
}

.ri-menu-4-fill:before {
  content: "\EF35"
}

.ri-menu-4-line:before {
  content: "\EF36"
}

.ri-menu-5-fill:before {
  content: "\EF37"
}

.ri-menu-5-line:before {
  content: "\EF38"
}

.ri-menu-add-fill:before {
  content: "\EF39"
}

.ri-menu-add-line:before {
  content: "\EF3A"
}

.ri-menu-fill:before {
  content: "\EF3B"
}

.ri-menu-fold-fill:before {
  content: "\EF3C"
}

.ri-menu-fold-line:before {
  content: "\EF3D"
}

.ri-menu-line:before {
  content: "\EF3E"
}

.ri-menu-unfold-fill:before {
  content: "\EF3F"
}

.ri-menu-unfold-line:before {
  content: "\EF40"
}

.ri-merge-cells-horizontal:before {
  content: "\EF41"
}

.ri-merge-cells-vertical:before {
  content: "\EF42"
}

.ri-message-2-fill:before {
  content: "\EF43"
}

.ri-message-2-line:before {
  content: "\EF44"
}

.ri-message-3-fill:before {
  content: "\EF45"
}

.ri-message-3-line:before {
  content: "\EF46"
}

.ri-message-fill:before {
  content: "\EF47"
}

.ri-message-line:before {
  content: "\EF48"
}

.ri-messenger-fill:before {
  content: "\EF49"
}

.ri-messenger-line:before {
  content: "\EF4A"
}

.ri-meteor-fill:before {
  content: "\EF4B"
}

.ri-meteor-line:before {
  content: "\EF4C"
}

.ri-mic-2-fill:before {
  content: "\EF4D"
}

.ri-mic-2-line:before {
  content: "\EF4E"
}

.ri-mic-fill:before {
  content: "\EF4F"
}

.ri-mic-line:before {
  content: "\EF50"
}

.ri-mic-off-fill:before {
  content: "\EF51"
}

.ri-mic-off-line:before {
  content: "\EF52"
}

.ri-mickey-fill:before {
  content: "\EF53"
}

.ri-mickey-line:before {
  content: "\EF54"
}

.ri-microscope-fill:before {
  content: "\EF55"
}

.ri-microscope-line:before {
  content: "\EF56"
}

.ri-microsoft-fill:before {
  content: "\EF57"
}

.ri-microsoft-line:before {
  content: "\EF58"
}

.ri-mind-map:before {
  content: "\EF59"
}

.ri-mini-program-fill:before {
  content: "\EF5A"
}

.ri-mini-program-line:before {
  content: "\EF5B"
}

.ri-mist-fill:before {
  content: "\EF5C"
}

.ri-mist-line:before {
  content: "\EF5D"
}

.ri-money-cny-box-fill:before {
  content: "\EF5E"
}

.ri-money-cny-box-line:before {
  content: "\EF5F"
}

.ri-money-cny-circle-fill:before {
  content: "\EF60"
}

.ri-money-cny-circle-line:before {
  content: "\EF61"
}

.ri-money-dollar-box-fill:before {
  content: "\EF62"
}

.ri-money-dollar-box-line:before {
  content: "\EF63"
}

.ri-money-dollar-circle-fill:before {
  content: "\EF64"
}

.ri-money-dollar-circle-line:before {
  content: "\EF65"
}

.ri-money-euro-box-fill:before {
  content: "\EF66"
}

.ri-money-euro-box-line:before {
  content: "\EF67"
}

.ri-money-euro-circle-fill:before {
  content: "\EF68"
}

.ri-money-euro-circle-line:before {
  content: "\EF69"
}

.ri-money-pound-box-fill:before {
  content: "\EF6A"
}

.ri-money-pound-box-line:before {
  content: "\EF6B"
}

.ri-money-pound-circle-fill:before {
  content: "\EF6C"
}

.ri-money-pound-circle-line:before {
  content: "\EF6D"
}

.ri-moon-clear-fill:before {
  content: "\EF6E"
}

.ri-moon-clear-line:before {
  content: "\EF6F"
}

.ri-moon-cloudy-fill:before {
  content: "\EF70"
}

.ri-moon-cloudy-line:before {
  content: "\EF71"
}

.ri-moon-fill:before {
  content: "\EF72"
}

.ri-moon-foggy-fill:before {
  content: "\EF73"
}

.ri-moon-foggy-line:before {
  content: "\EF74"
}

.ri-moon-line:before {
  content: "\EF75"
}

.ri-more-2-fill:before {
  content: "\EF76"
}

.ri-more-2-line:before {
  content: "\EF77"
}

.ri-more-fill:before {
  content: "\EF78"
}

.ri-more-line:before {
  content: "\EF79"
}

.ri-motorbike-fill:before {
  content: "\EF7A"
}

.ri-motorbike-line:before {
  content: "\EF7B"
}

.ri-mouse-fill:before {
  content: "\EF7C"
}

.ri-mouse-line:before {
  content: "\EF7D"
}

.ri-movie-2-fill:before {
  content: "\EF7E"
}

.ri-movie-2-line:before {
  content: "\EF7F"
}

.ri-movie-fill:before {
  content: "\EF80"
}

.ri-movie-line:before {
  content: "\EF81"
}

.ri-music-2-fill:before {
  content: "\EF82"
}

.ri-music-2-line:before {
  content: "\EF83"
}

.ri-music-fill:before {
  content: "\EF84"
}

.ri-music-line:before {
  content: "\EF85"
}

.ri-mv-fill:before {
  content: "\EF86"
}

.ri-mv-line:before {
  content: "\EF87"
}

.ri-navigation-fill:before {
  content: "\EF88"
}

.ri-navigation-line:before {
  content: "\EF89"
}

.ri-netease-cloud-music-fill:before {
  content: "\EF8A"
}

.ri-netease-cloud-music-line:before {
  content: "\EF8B"
}

.ri-netflix-fill:before {
  content: "\EF8C"
}

.ri-netflix-line:before {
  content: "\EF8D"
}

.ri-newspaper-fill:before {
  content: "\EF8E"
}

.ri-newspaper-line:before {
  content: "\EF8F"
}

.ri-node-tree:before {
  content: "\EF90"
}

.ri-notification-2-fill:before {
  content: "\EF91"
}

.ri-notification-2-line:before {
  content: "\EF92"
}

.ri-notification-3-fill:before {
  content: "\EF93"
}

.ri-notification-3-line:before {
  content: "\EF94"
}

.ri-notification-4-fill:before {
  content: "\EF95"
}

.ri-notification-4-line:before {
  content: "\EF96"
}

.ri-notification-badge-fill:before {
  content: "\EF97"
}

.ri-notification-badge-line:before {
  content: "\EF98"
}

.ri-notification-fill:before {
  content: "\EF99"
}

.ri-notification-line:before {
  content: "\EF9A"
}

.ri-notification-off-fill:before {
  content: "\EF9B"
}

.ri-notification-off-line:before {
  content: "\EF9C"
}

.ri-npmjs-fill:before {
  content: "\EF9D"
}

.ri-npmjs-line:before {
  content: "\EF9E"
}

.ri-number-0:before {
  content: "\EF9F"
}

.ri-number-1:before {
  content: "\EFA0"
}

.ri-number-2:before {
  content: "\EFA1"
}

.ri-number-3:before {
  content: "\EFA2"
}

.ri-number-4:before {
  content: "\EFA3"
}

.ri-number-5:before {
  content: "\EFA4"
}

.ri-number-6:before {
  content: "\EFA5"
}

.ri-number-7:before {
  content: "\EFA6"
}

.ri-number-8:before {
  content: "\EFA7"
}

.ri-number-9:before {
  content: "\EFA8"
}

.ri-numbers-fill:before {
  content: "\EFA9"
}

.ri-numbers-line:before {
  content: "\EFAA"
}

.ri-nurse-fill:before {
  content: "\EFAB"
}

.ri-nurse-line:before {
  content: "\EFAC"
}

.ri-oil-fill:before {
  content: "\EFAD"
}

.ri-oil-line:before {
  content: "\EFAE"
}

.ri-omega:before {
  content: "\EFAF"
}

.ri-open-arm-fill:before {
  content: "\EFB0"
}

.ri-open-arm-line:before {
  content: "\EFB1"
}

.ri-open-source-fill:before {
  content: "\EFB2"
}

.ri-open-source-line:before {
  content: "\EFB3"
}

.ri-opera-fill:before {
  content: "\EFB4"
}

.ri-opera-line:before {
  content: "\EFB5"
}

.ri-order-play-fill:before {
  content: "\EFB6"
}

.ri-order-play-line:before {
  content: "\EFB7"
}

.ri-organization-chart:before {
  content: "\EFB8"
}

.ri-outlet-2-fill:before {
  content: "\EFB9"
}

.ri-outlet-2-line:before {
  content: "\EFBA"
}

.ri-outlet-fill:before {
  content: "\EFBB"
}

.ri-outlet-line:before {
  content: "\EFBC"
}

.ri-page-separator:before {
  content: "\EFBD"
}

.ri-pages-fill:before {
  content: "\EFBE"
}

.ri-pages-line:before {
  content: "\EFBF"
}

.ri-paint-brush-fill:before {
  content: "\EFC0"
}

.ri-paint-brush-line:before {
  content: "\EFC1"
}

.ri-paint-fill:before {
  content: "\EFC2"
}

.ri-paint-line:before {
  content: "\EFC3"
}

.ri-palette-fill:before {
  content: "\EFC4"
}

.ri-palette-line:before {
  content: "\EFC5"
}

.ri-pantone-fill:before {
  content: "\EFC6"
}

.ri-pantone-line:before {
  content: "\EFC7"
}

.ri-paragraph:before {
  content: "\EFC8"
}

.ri-parent-fill:before {
  content: "\EFC9"
}

.ri-parent-line:before {
  content: "\EFCA"
}

.ri-parentheses-fill:before {
  content: "\EFCB"
}

.ri-parentheses-line:before {
  content: "\EFCC"
}

.ri-parking-box-fill:before {
  content: "\EFCD"
}

.ri-parking-box-line:before {
  content: "\EFCE"
}

.ri-parking-fill:before {
  content: "\EFCF"
}

.ri-parking-line:before {
  content: "\EFD0"
}

.ri-passport-fill:before {
  content: "\EFD1"
}

.ri-passport-line:before {
  content: "\EFD2"
}

.ri-patreon-fill:before {
  content: "\EFD3"
}

.ri-patreon-line:before {
  content: "\EFD4"
}

.ri-pause-circle-fill:before {
  content: "\EFD5"
}

.ri-pause-circle-line:before {
  content: "\EFD6"
}

.ri-pause-fill:before {
  content: "\EFD7"
}

.ri-pause-line:before {
  content: "\EFD8"
}

.ri-pause-mini-fill:before {
  content: "\EFD9"
}

.ri-pause-mini-line:before {
  content: "\EFDA"
}

.ri-paypal-fill:before {
  content: "\EFDB"
}

.ri-paypal-line:before {
  content: "\EFDC"
}

.ri-pen-nib-fill:before {
  content: "\EFDD"
}

.ri-pen-nib-line:before {
  content: "\EFDE"
}

.ri-pencil-fill:before {
  content: "\EFDF"
}

.ri-pencil-line:before {
  content: "\EFE0"
}

.ri-pencil-ruler-2-fill:before {
  content: "\EFE1"
}

.ri-pencil-ruler-2-line:before {
  content: "\EFE2"
}

.ri-pencil-ruler-fill:before {
  content: "\EFE3"
}

.ri-pencil-ruler-line:before {
  content: "\EFE4"
}

.ri-percent-fill:before {
  content: "\EFE5"
}

.ri-percent-line:before {
  content: "\EFE6"
}

.ri-phone-camera-fill:before {
  content: "\EFE7"
}

.ri-phone-camera-line:before {
  content: "\EFE8"
}

.ri-phone-fill:before {
  content: "\EFE9"
}

.ri-phone-find-fill:before {
  content: "\EFEA"
}

.ri-phone-find-line:before {
  content: "\EFEB"
}

.ri-phone-line:before {
  content: "\EFEC"
}

.ri-phone-lock-fill:before {
  content: "\EFED"
}

.ri-phone-lock-line:before {
  content: "\EFEE"
}

.ri-picture-in-picture-2-fill:before {
  content: "\EFEF"
}

.ri-picture-in-picture-2-line:before {
  content: "\EFF0"
}

.ri-picture-in-picture-exit-fill:before {
  content: "\EFF1"
}

.ri-picture-in-picture-exit-line:before {
  content: "\EFF2"
}

.ri-picture-in-picture-fill:before {
  content: "\EFF3"
}

.ri-picture-in-picture-line:before {
  content: "\EFF4"
}

.ri-pie-chart-2-fill:before {
  content: "\EFF5"
}

.ri-pie-chart-2-line:before {
  content: "\EFF6"
}

.ri-pie-chart-box-fill:before {
  content: "\EFF7"
}

.ri-pie-chart-box-line:before {
  content: "\EFF8"
}

.ri-pie-chart-fill:before {
  content: "\EFF9"
}

.ri-pie-chart-line:before {
  content: "\EFFA"
}

.ri-pin-distance-fill:before {
  content: "\EFFB"
}

.ri-pin-distance-line:before {
  content: "\EFFC"
}

.ri-ping-pong-fill:before {
  content: "\EFFD"
}

.ri-ping-pong-line:before {
  content: "\EFFE"
}

.ri-pinterest-fill:before {
  content: "\EFFF"
}

.ri-pinterest-line:before {
  content: "\F000"
}

.ri-pinyin-input:before {
  content: "\F001"
}

.ri-pixelfed-fill:before {
  content: "\F002"
}

.ri-pixelfed-line:before {
  content: "\F003"
}

.ri-plane-fill:before {
  content: "\F004"
}

.ri-plane-line:before {
  content: "\F005"
}

.ri-plant-fill:before {
  content: "\F006"
}

.ri-plant-line:before {
  content: "\F007"
}

.ri-play-circle-fill:before {
  content: "\F008"
}

.ri-play-circle-line:before {
  content: "\F009"
}

.ri-play-fill:before {
  content: "\F00A"
}

.ri-play-line:before {
  content: "\F00B"
}

.ri-play-list-2-fill:before {
  content: "\F00C"
}

.ri-play-list-2-line:before {
  content: "\F00D"
}

.ri-play-list-add-fill:before {
  content: "\F00E"
}

.ri-play-list-add-line:before {
  content: "\F00F"
}

.ri-play-list-fill:before {
  content: "\F010"
}

.ri-play-list-line:before {
  content: "\F011"
}

.ri-play-mini-fill:before {
  content: "\F012"
}

.ri-play-mini-line:before {
  content: "\F013"
}

.ri-playstation-fill:before {
  content: "\F014"
}

.ri-playstation-line:before {
  content: "\F015"
}

.ri-plug-2-fill:before {
  content: "\F016"
}

.ri-plug-2-line:before {
  content: "\F017"
}

.ri-plug-fill:before {
  content: "\F018"
}

.ri-plug-line:before {
  content: "\F019"
}

.ri-polaroid-2-fill:before {
  content: "\F01A"
}

.ri-polaroid-2-line:before {
  content: "\F01B"
}

.ri-polaroid-fill:before {
  content: "\F01C"
}

.ri-polaroid-line:before {
  content: "\F01D"
}

.ri-police-car-fill:before {
  content: "\F01E"
}

.ri-police-car-line:before {
  content: "\F01F"
}

.ri-price-tag-2-fill:before {
  content: "\F020"
}

.ri-price-tag-2-line:before {
  content: "\F021"
}

.ri-price-tag-3-fill:before {
  content: "\F022"
}

.ri-price-tag-3-line:before {
  content: "\F023"
}

.ri-price-tag-fill:before {
  content: "\F024"
}

.ri-price-tag-line:before {
  content: "\F025"
}

.ri-printer-cloud-fill:before {
  content: "\F026"
}

.ri-printer-cloud-line:before {
  content: "\F027"
}

.ri-printer-fill:before {
  content: "\F028"
}

.ri-printer-line:before {
  content: "\F029"
}

.ri-product-hunt-fill:before {
  content: "\F02A"
}

.ri-product-hunt-line:before {
  content: "\F02B"
}

.ri-profile-fill:before {
  content: "\F02C"
}

.ri-profile-line:before {
  content: "\F02D"
}

.ri-projector-2-fill:before {
  content: "\F02E"
}

.ri-projector-2-line:before {
  content: "\F02F"
}

.ri-projector-fill:before {
  content: "\F030"
}

.ri-projector-line:before {
  content: "\F031"
}

.ri-psychotherapy-fill:before {
  content: "\F032"
}

.ri-psychotherapy-line:before {
  content: "\F033"
}

.ri-pulse-fill:before {
  content: "\F034"
}

.ri-pulse-line:before {
  content: "\F035"
}

.ri-pushpin-2-fill:before {
  content: "\F036"
}

.ri-pushpin-2-line:before {
  content: "\F037"
}

.ri-pushpin-fill:before {
  content: "\F038"
}

.ri-pushpin-line:before {
  content: "\F039"
}

.ri-qq-fill:before {
  content: "\F03A"
}

.ri-qq-line:before {
  content: "\F03B"
}

.ri-qr-code-fill:before {
  content: "\F03C"
}

.ri-qr-code-line:before {
  content: "\F03D"
}

.ri-qr-scan-2-fill:before {
  content: "\F03E"
}

.ri-qr-scan-2-line:before {
  content: "\F03F"
}

.ri-qr-scan-fill:before {
  content: "\F040"
}

.ri-qr-scan-line:before {
  content: "\F041"
}

.ri-question-answer-fill:before {
  content: "\F042"
}

.ri-question-answer-line:before {
  content: "\F043"
}

.ri-question-fill:before {
  content: "\F044"
}

.ri-question-line:before {
  content: "\F045"
}

.ri-question-mark:before {
  content: "\F046"
}

.ri-questionnaire-fill:before {
  content: "\F047"
}

.ri-questionnaire-line:before {
  content: "\F048"
}

.ri-quill-pen-fill:before {
  content: "\F049"
}

.ri-quill-pen-line:before {
  content: "\F04A"
}

.ri-radar-fill:before {
  content: "\F04B"
}

.ri-radar-line:before {
  content: "\F04C"
}

.ri-radio-2-fill:before {
  content: "\F04D"
}

.ri-radio-2-line:before {
  content: "\F04E"
}

.ri-radio-button-fill:before {
  content: "\F04F"
}

.ri-radio-button-line:before {
  content: "\F050"
}

.ri-radio-fill:before {
  content: "\F051"
}

.ri-radio-line:before {
  content: "\F052"
}

.ri-rainbow-fill:before {
  content: "\F053"
}

.ri-rainbow-line:before {
  content: "\F054"
}

.ri-rainy-fill:before {
  content: "\F055"
}

.ri-rainy-line:before {
  content: "\F056"
}

.ri-reactjs-fill:before {
  content: "\F057"
}

.ri-reactjs-line:before {
  content: "\F058"
}

.ri-record-circle-fill:before {
  content: "\F059"
}

.ri-record-circle-line:before {
  content: "\F05A"
}

.ri-record-mail-fill:before {
  content: "\F05B"
}

.ri-record-mail-line:before {
  content: "\F05C"
}

.ri-recycle-fill:before {
  content: "\F05D"
}

.ri-recycle-line:before {
  content: "\F05E"
}

.ri-red-packet-fill:before {
  content: "\F05F"
}

.ri-red-packet-line:before {
  content: "\F060"
}

.ri-reddit-fill:before {
  content: "\F061"
}

.ri-reddit-line:before {
  content: "\F062"
}

.ri-refresh-fill:before {
  content: "\F063"
}

.ri-refresh-line:before {
  content: "\F064"
}

.ri-refund-2-fill:before {
  content: "\F065"
}

.ri-refund-2-line:before {
  content: "\F066"
}

.ri-refund-fill:before {
  content: "\F067"
}

.ri-refund-line:before {
  content: "\F068"
}

.ri-registered-fill:before {
  content: "\F069"
}

.ri-registered-line:before {
  content: "\F06A"
}

.ri-remixicon-fill:before {
  content: "\F06B"
}

.ri-remixicon-line:before {
  content: "\F06C"
}

.ri-remote-control-2-fill:before {
  content: "\F06D"
}

.ri-remote-control-2-line:before {
  content: "\F06E"
}

.ri-remote-control-fill:before {
  content: "\F06F"
}

.ri-remote-control-line:before {
  content: "\F070"
}

.ri-repeat-2-fill:before {
  content: "\F071"
}

.ri-repeat-2-line:before {
  content: "\F072"
}

.ri-repeat-fill:before {
  content: "\F073"
}

.ri-repeat-line:before {
  content: "\F074"
}

.ri-repeat-one-fill:before {
  content: "\F075"
}

.ri-repeat-one-line:before {
  content: "\F076"
}

.ri-reply-all-fill:before {
  content: "\F077"
}

.ri-reply-all-line:before {
  content: "\F078"
}

.ri-reply-fill:before {
  content: "\F079"
}

.ri-reply-line:before {
  content: "\F07A"
}

.ri-reserved-fill:before {
  content: "\F07B"
}

.ri-reserved-line:before {
  content: "\F07C"
}

.ri-rest-time-fill:before {
  content: "\F07D"
}

.ri-rest-time-line:before {
  content: "\F07E"
}

.ri-restart-fill:before {
  content: "\F07F"
}

.ri-restart-line:before {
  content: "\F080"
}

.ri-restaurant-2-fill:before {
  content: "\F081"
}

.ri-restaurant-2-line:before {
  content: "\F082"
}

.ri-restaurant-fill:before {
  content: "\F083"
}

.ri-restaurant-line:before {
  content: "\F084"
}

.ri-rewind-fill:before {
  content: "\F085"
}

.ri-rewind-line:before {
  content: "\F086"
}

.ri-rewind-mini-fill:before {
  content: "\F087"
}

.ri-rewind-mini-line:before {
  content: "\F088"
}

.ri-rhythm-fill:before {
  content: "\F089"
}

.ri-rhythm-line:before {
  content: "\F08A"
}

.ri-riding-fill:before {
  content: "\F08B"
}

.ri-riding-line:before {
  content: "\F08C"
}

.ri-road-map-fill:before {
  content: "\F08D"
}

.ri-road-map-line:before {
  content: "\F08E"
}

.ri-roadster-fill:before {
  content: "\F08F"
}

.ri-roadster-line:before {
  content: "\F090"
}

.ri-robot-fill:before {
  content: "\F091"
}

.ri-robot-line:before {
  content: "\F092"
}

.ri-rocket-2-fill:before {
  content: "\F093"
}

.ri-rocket-2-line:before {
  content: "\F094"
}

.ri-rocket-fill:before {
  content: "\F095"
}

.ri-rocket-line:before {
  content: "\F096"
}

.ri-rotate-lock-fill:before {
  content: "\F097"
}

.ri-rotate-lock-line:before {
  content: "\F098"
}

.ri-rounded-corner:before {
  content: "\F099"
}

.ri-route-fill:before {
  content: "\F09A"
}

.ri-route-line:before {
  content: "\F09B"
}

.ri-router-fill:before {
  content: "\F09C"
}

.ri-router-line:before {
  content: "\F09D"
}

.ri-rss-fill:before {
  content: "\F09E"
}

.ri-rss-line:before {
  content: "\F09F"
}

.ri-ruler-2-fill:before {
  content: "\F0A0"
}

.ri-ruler-2-line:before {
  content: "\F0A1"
}

.ri-ruler-fill:before {
  content: "\F0A2"
}

.ri-ruler-line:before {
  content: "\F0A3"
}

.ri-run-fill:before {
  content: "\F0A4"
}

.ri-run-line:before {
  content: "\F0A5"
}

.ri-safari-fill:before {
  content: "\F0A6"
}

.ri-safari-line:before {
  content: "\F0A7"
}

.ri-safe-2-fill:before {
  content: "\F0A8"
}

.ri-safe-2-line:before {
  content: "\F0A9"
}

.ri-safe-fill:before {
  content: "\F0AA"
}

.ri-safe-line:before {
  content: "\F0AB"
}

.ri-sailboat-fill:before {
  content: "\F0AC"
}

.ri-sailboat-line:before {
  content: "\F0AD"
}

.ri-save-2-fill:before {
  content: "\F0AE"
}

.ri-save-2-line:before {
  content: "\F0AF"
}

.ri-save-3-fill:before {
  content: "\F0B0"
}

.ri-save-3-line:before {
  content: "\F0B1"
}

.ri-save-fill:before {
  content: "\F0B2"
}

.ri-save-line:before {
  content: "\F0B3"
}

.ri-scales-2-fill:before {
  content: "\F0B4"
}

.ri-scales-2-line:before {
  content: "\F0B5"
}

.ri-scales-3-fill:before {
  content: "\F0B6"
}

.ri-scales-3-line:before {
  content: "\F0B7"
}

.ri-scales-fill:before {
  content: "\F0B8"
}

.ri-scales-line:before {
  content: "\F0B9"
}

.ri-scan-2-fill:before {
  content: "\F0BA"
}

.ri-scan-2-line:before {
  content: "\F0BB"
}

.ri-scan-fill:before {
  content: "\F0BC"
}

.ri-scan-line:before {
  content: "\F0BD"
}

.ri-scissors-2-fill:before {
  content: "\F0BE"
}

.ri-scissors-2-line:before {
  content: "\F0BF"
}

.ri-scissors-cut-fill:before {
  content: "\F0C0"
}

.ri-scissors-cut-line:before {
  content: "\F0C1"
}

.ri-scissors-fill:before {
  content: "\F0C2"
}

.ri-scissors-line:before {
  content: "\F0C3"
}

.ri-screenshot-2-fill:before {
  content: "\F0C4"
}

.ri-screenshot-2-line:before {
  content: "\F0C5"
}

.ri-screenshot-fill:before {
  content: "\F0C6"
}

.ri-screenshot-line:before {
  content: "\F0C7"
}

.ri-sd-card-fill:before {
  content: "\F0C8"
}

.ri-sd-card-line:before {
  content: "\F0C9"
}

.ri-sd-card-mini-fill:before {
  content: "\F0CA"
}

.ri-sd-card-mini-line:before {
  content: "\F0CB"
}

.ri-search-2-fill:before {
  content: "\F0CC"
}

.ri-search-2-line:before {
  content: "\F0CD"
}

.ri-search-eye-fill:before {
  content: "\F0CE"
}

.ri-search-eye-line:before {
  content: "\F0CF"
}

.ri-search-fill:before {
  content: "\F0D0"
}

.ri-search-line:before {
  content: "\F0D1"
}

.ri-secure-payment-fill:before {
  content: "\F0D2"
}

.ri-secure-payment-line:before {
  content: "\F0D3"
}

.ri-seedling-fill:before {
  content: "\F0D4"
}

.ri-seedling-line:before {
  content: "\F0D5"
}

.ri-send-backward:before {
  content: "\F0D6"
}

.ri-send-plane-2-fill:before {
  content: "\F0D7"
}

.ri-send-plane-2-line:before {
  content: "\F0D8"
}

.ri-send-plane-fill:before {
  content: "\F0D9"
}

.ri-send-plane-line:before {
  content: "\F0DA"
}

.ri-send-to-back:before {
  content: "\F0DB"
}

.ri-sensor-fill:before {
  content: "\F0DC"
}

.ri-sensor-line:before {
  content: "\F0DD"
}

.ri-separator:before {
  content: "\F0DE"
}

.ri-server-fill:before {
  content: "\F0DF"
}

.ri-server-line:before {
  content: "\F0E0"
}

.ri-service-fill:before {
  content: "\F0E1"
}

.ri-service-line:before {
  content: "\F0E2"
}

.ri-settings-2-fill:before {
  content: "\F0E3"
}

.ri-settings-2-line:before {
  content: "\F0E4"
}

.ri-settings-3-fill:before {
  content: "\F0E5"
}

.ri-settings-3-line:before {
  content: "\F0E6"
}

.ri-settings-4-fill:before {
  content: "\F0E7"
}

.ri-settings-4-line:before {
  content: "\F0E8"
}

.ri-settings-5-fill:before {
  content: "\F0E9"
}

.ri-settings-5-line:before {
  content: "\F0EA"
}

.ri-settings-6-fill:before {
  content: "\F0EB"
}

.ri-settings-6-line:before {
  content: "\F0EC"
}

.ri-settings-fill:before {
  content: "\F0ED"
}

.ri-settings-line:before {
  content: "\F0EE"
}

.ri-shape-2-fill:before {
  content: "\F0EF"
}

.ri-shape-2-line:before {
  content: "\F0F0"
}

.ri-shape-fill:before {
  content: "\F0F1"
}

.ri-shape-line:before {
  content: "\F0F2"
}

.ri-share-box-fill:before {
  content: "\F0F3"
}

.ri-share-box-line:before {
  content: "\F0F4"
}

.ri-share-circle-fill:before {
  content: "\F0F5"
}

.ri-share-circle-line:before {
  content: "\F0F6"
}

.ri-share-fill:before {
  content: "\F0F7"
}

.ri-share-forward-2-fill:before {
  content: "\F0F8"
}

.ri-share-forward-2-line:before {
  content: "\F0F9"
}

.ri-share-forward-box-fill:before {
  content: "\F0FA"
}

.ri-share-forward-box-line:before {
  content: "\F0FB"
}

.ri-share-forward-fill:before {
  content: "\F0FC"
}

.ri-share-forward-line:before {
  content: "\F0FD"
}

.ri-share-line:before {
  content: "\F0FE"
}

.ri-shield-check-fill:before {
  content: "\F0FF"
}

.ri-shield-check-line:before {
  content: "\F100"
}

.ri-shield-cross-fill:before {
  content: "\F101"
}

.ri-shield-cross-line:before {
  content: "\F102"
}

.ri-shield-fill:before {
  content: "\F103"
}

.ri-shield-flash-fill:before {
  content: "\F104"
}

.ri-shield-flash-line:before {
  content: "\F105"
}

.ri-shield-keyhole-fill:before {
  content: "\F106"
}

.ri-shield-keyhole-line:before {
  content: "\F107"
}

.ri-shield-line:before {
  content: "\F108"
}

.ri-shield-star-fill:before {
  content: "\F109"
}

.ri-shield-star-line:before {
  content: "\F10A"
}

.ri-shield-user-fill:before {
  content: "\F10B"
}

.ri-shield-user-line:before {
  content: "\F10C"
}

.ri-ship-2-fill:before {
  content: "\F10D"
}

.ri-ship-2-line:before {
  content: "\F10E"
}

.ri-ship-fill:before {
  content: "\F10F"
}

.ri-ship-line:before {
  content: "\F110"
}

.ri-shirt-fill:before {
  content: "\F111"
}

.ri-shirt-line:before {
  content: "\F112"
}

.ri-shopping-bag-2-fill:before {
  content: "\F113"
}

.ri-shopping-bag-2-line:before {
  content: "\F114"
}

.ri-shopping-bag-3-fill:before {
  content: "\F115"
}

.ri-shopping-bag-3-line:before {
  content: "\F116"
}

.ri-shopping-bag-fill:before {
  content: "\F117"
}

.ri-shopping-bag-line:before {
  content: "\F118"
}

.ri-shopping-basket-2-fill:before {
  content: "\F119"
}

.ri-shopping-basket-2-line:before {
  content: "\F11A"
}

.ri-shopping-basket-fill:before {
  content: "\F11B"
}

.ri-shopping-basket-line:before {
  content: "\F11C"
}

.ri-shopping-cart-2-fill:before {
  content: "\F11D"
}

.ri-shopping-cart-2-line:before {
  content: "\F11E"
}

.ri-shopping-cart-fill:before {
  content: "\F11F"
}

.ri-shopping-cart-line:before {
  content: "\F120"
}

.ri-showers-fill:before {
  content: "\F121"
}

.ri-showers-line:before {
  content: "\F122"
}

.ri-shuffle-fill:before {
  content: "\F123"
}

.ri-shuffle-line:before {
  content: "\F124"
}

.ri-shut-down-fill:before {
  content: "\F125"
}

.ri-shut-down-line:before {
  content: "\F126"
}

.ri-side-bar-fill:before {
  content: "\F127"
}

.ri-side-bar-line:before {
  content: "\F128"
}

.ri-signal-tower-fill:before {
  content: "\F129"
}

.ri-signal-tower-line:before {
  content: "\F12A"
}

.ri-signal-wifi-1-fill:before {
  content: "\F12B"
}

.ri-signal-wifi-1-line:before {
  content: "\F12C"
}

.ri-signal-wifi-2-fill:before {
  content: "\F12D"
}

.ri-signal-wifi-2-line:before {
  content: "\F12E"
}

.ri-signal-wifi-3-fill:before {
  content: "\F12F"
}

.ri-signal-wifi-3-line:before {
  content: "\F130"
}

.ri-signal-wifi-error-fill:before {
  content: "\F131"
}

.ri-signal-wifi-error-line:before {
  content: "\F132"
}

.ri-signal-wifi-fill:before {
  content: "\F133"
}

.ri-signal-wifi-line:before {
  content: "\F134"
}

.ri-signal-wifi-off-fill:before {
  content: "\F135"
}

.ri-signal-wifi-off-line:before {
  content: "\F136"
}

.ri-sim-card-2-fill:before {
  content: "\F137"
}

.ri-sim-card-2-line:before {
  content: "\F138"
}

.ri-sim-card-fill:before {
  content: "\F139"
}

.ri-sim-card-line:before {
  content: "\F13A"
}

.ri-single-quotes-l:before {
  content: "\F13B"
}

.ri-single-quotes-r:before {
  content: "\F13C"
}

.ri-sip-fill:before {
  content: "\F13D"
}

.ri-sip-line:before {
  content: "\F13E"
}

.ri-skip-back-fill:before {
  content: "\F13F"
}

.ri-skip-back-line:before {
  content: "\F140"
}

.ri-skip-back-mini-fill:before {
  content: "\F141"
}

.ri-skip-back-mini-line:before {
  content: "\F142"
}

.ri-skip-forward-fill:before {
  content: "\F143"
}

.ri-skip-forward-line:before {
  content: "\F144"
}

.ri-skip-forward-mini-fill:before {
  content: "\F145"
}

.ri-skip-forward-mini-line:before {
  content: "\F146"
}

.ri-skull-2-fill:before {
  content: "\F147"
}

.ri-skull-2-line:before {
  content: "\F148"
}

.ri-skull-fill:before {
  content: "\F149"
}

.ri-skull-line:before {
  content: "\F14A"
}

.ri-skype-fill:before {
  content: "\F14B"
}

.ri-skype-line:before {
  content: "\F14C"
}

.ri-slack-fill:before {
  content: "\F14D"
}

.ri-slack-line:before {
  content: "\F14E"
}

.ri-slice-fill:before {
  content: "\F14F"
}

.ri-slice-line:before {
  content: "\F150"
}

.ri-slideshow-2-fill:before {
  content: "\F151"
}

.ri-slideshow-2-line:before {
  content: "\F152"
}

.ri-slideshow-3-fill:before {
  content: "\F153"
}

.ri-slideshow-3-line:before {
  content: "\F154"
}

.ri-slideshow-4-fill:before {
  content: "\F155"
}

.ri-slideshow-4-line:before {
  content: "\F156"
}

.ri-slideshow-fill:before {
  content: "\F157"
}

.ri-slideshow-line:before {
  content: "\F158"
}

.ri-smartphone-fill:before {
  content: "\F159"
}

.ri-smartphone-line:before {
  content: "\F15A"
}

.ri-snapchat-fill:before {
  content: "\F15B"
}

.ri-snapchat-line:before {
  content: "\F15C"
}

.ri-snowy-fill:before {
  content: "\F15D"
}

.ri-snowy-line:before {
  content: "\F15E"
}

.ri-sort-asc:before {
  content: "\F15F"
}

.ri-sort-desc:before {
  content: "\F160"
}

.ri-sound-module-fill:before {
  content: "\F161"
}

.ri-sound-module-line:before {
  content: "\F162"
}

.ri-soundcloud-fill:before {
  content: "\F163"
}

.ri-soundcloud-line:before {
  content: "\F164"
}

.ri-space-ship-fill:before {
  content: "\F165"
}

.ri-space-ship-line:before {
  content: "\F166"
}

.ri-space:before {
  content: "\F167"
}

.ri-spam-2-fill:before {
  content: "\F168"
}

.ri-spam-2-line:before {
  content: "\F169"
}

.ri-spam-3-fill:before {
  content: "\F16A"
}

.ri-spam-3-line:before {
  content: "\F16B"
}

.ri-spam-fill:before {
  content: "\F16C"
}

.ri-spam-line:before {
  content: "\F16D"
}

.ri-speaker-2-fill:before {
  content: "\F16E"
}

.ri-speaker-2-line:before {
  content: "\F16F"
}

.ri-speaker-3-fill:before {
  content: "\F170"
}

.ri-speaker-3-line:before {
  content: "\F171"
}

.ri-speaker-fill:before {
  content: "\F172"
}

.ri-speaker-line:before {
  content: "\F173"
}

.ri-spectrum-fill:before {
  content: "\F174"
}

.ri-spectrum-line:before {
  content: "\F175"
}

.ri-speed-fill:before {
  content: "\F176"
}

.ri-speed-line:before {
  content: "\F177"
}

.ri-speed-mini-fill:before {
  content: "\F178"
}

.ri-speed-mini-line:before {
  content: "\F179"
}

.ri-split-cells-horizontal:before {
  content: "\F17A"
}

.ri-split-cells-vertical:before {
  content: "\F17B"
}

.ri-spotify-fill:before {
  content: "\F17C"
}

.ri-spotify-line:before {
  content: "\F17D"
}

.ri-spy-fill:before {
  content: "\F17E"
}

.ri-spy-line:before {
  content: "\F17F"
}

.ri-stack-fill:before {
  content: "\F180"
}

.ri-stack-line:before {
  content: "\F181"
}

.ri-stack-overflow-fill:before {
  content: "\F182"
}

.ri-stack-overflow-line:before {
  content: "\F183"
}

.ri-stackshare-fill:before {
  content: "\F184"
}

.ri-stackshare-line:before {
  content: "\F185"
}

.ri-star-fill:before {
  content: "\F186"
}

.ri-star-half-fill:before {
  content: "\F187"
}

.ri-star-half-line:before {
  content: "\F188"
}

.ri-star-half-s-fill:before {
  content: "\F189"
}

.ri-star-half-s-line:before {
  content: "\F18A"
}

.ri-star-line:before {
  content: "\F18B"
}

.ri-star-s-fill:before {
  content: "\F18C"
}

.ri-star-s-line:before {
  content: "\F18D"
}

.ri-star-smile-fill:before {
  content: "\F18E"
}

.ri-star-smile-line:before {
  content: "\F18F"
}

.ri-steam-fill:before {
  content: "\F190"
}

.ri-steam-line:before {
  content: "\F191"
}

.ri-steering-2-fill:before {
  content: "\F192"
}

.ri-steering-2-line:before {
  content: "\F193"
}

.ri-steering-fill:before {
  content: "\F194"
}

.ri-steering-line:before {
  content: "\F195"
}

.ri-stethoscope-fill:before {
  content: "\F196"
}

.ri-stethoscope-line:before {
  content: "\F197"
}

.ri-sticky-note-2-fill:before {
  content: "\F198"
}

.ri-sticky-note-2-line:before {
  content: "\F199"
}

.ri-sticky-note-fill:before {
  content: "\F19A"
}

.ri-sticky-note-line:before {
  content: "\F19B"
}

.ri-stock-fill:before {
  content: "\F19C"
}

.ri-stock-line:before {
  content: "\F19D"
}

.ri-stop-circle-fill:before {
  content: "\F19E"
}

.ri-stop-circle-line:before {
  content: "\F19F"
}

.ri-stop-fill:before {
  content: "\F1A0"
}

.ri-stop-line:before {
  content: "\F1A1"
}

.ri-stop-mini-fill:before {
  content: "\F1A2"
}

.ri-stop-mini-line:before {
  content: "\F1A3"
}

.ri-store-2-fill:before {
  content: "\F1A4"
}

.ri-store-2-line:before {
  content: "\F1A5"
}

.ri-store-3-fill:before {
  content: "\F1A6"
}

.ri-store-3-line:before {
  content: "\F1A7"
}

.ri-store-fill:before {
  content: "\F1A8"
}

.ri-store-line:before {
  content: "\F1A9"
}

.ri-strikethrough-2:before {
  content: "\F1AA"
}

.ri-strikethrough:before {
  content: "\F1AB"
}

.ri-subscript-2:before {
  content: "\F1AC"
}

.ri-subscript:before {
  content: "\F1AD"
}

.ri-subtract-fill:before {
  content: "\F1AE"
}

.ri-subtract-line:before {
  content: "\F1AF"
}

.ri-subway-fill:before {
  content: "\F1B0"
}

.ri-subway-line:before {
  content: "\F1B1"
}

.ri-subway-wifi-fill:before {
  content: "\F1B2"
}

.ri-subway-wifi-line:before {
  content: "\F1B3"
}

.ri-suitcase-2-fill:before {
  content: "\F1B4"
}

.ri-suitcase-2-line:before {
  content: "\F1B5"
}

.ri-suitcase-3-fill:before {
  content: "\F1B6"
}

.ri-suitcase-3-line:before {
  content: "\F1B7"
}

.ri-suitcase-fill:before {
  content: "\F1B8"
}

.ri-suitcase-line:before {
  content: "\F1B9"
}

.ri-sun-cloudy-fill:before {
  content: "\F1BA"
}

.ri-sun-cloudy-line:before {
  content: "\F1BB"
}

.ri-sun-fill:before {
  content: "\F1BC"
}

.ri-sun-foggy-fill:before {
  content: "\F1BD"
}

.ri-sun-foggy-line:before {
  content: "\F1BE"
}

.ri-sun-line:before {
  content: "\F1BF"
}

.ri-superscript-2:before {
  content: "\F1C0"
}

.ri-superscript:before {
  content: "\F1C1"
}

.ri-surgical-mask-fill:before {
  content: "\F1C2"
}

.ri-surgical-mask-line:before {
  content: "\F1C3"
}

.ri-surround-sound-fill:before {
  content: "\F1C4"
}

.ri-surround-sound-line:before {
  content: "\F1C5"
}

.ri-survey-fill:before {
  content: "\F1C6"
}

.ri-survey-line:before {
  content: "\F1C7"
}

.ri-swap-box-fill:before {
  content: "\F1C8"
}

.ri-swap-box-line:before {
  content: "\F1C9"
}

.ri-swap-fill:before {
  content: "\F1CA"
}

.ri-swap-line:before {
  content: "\F1CB"
}

.ri-switch-fill:before {
  content: "\F1CC"
}

.ri-switch-line:before {
  content: "\F1CD"
}

.ri-sword-fill:before {
  content: "\F1CE"
}

.ri-sword-line:before {
  content: "\F1CF"
}

.ri-syringe-fill:before {
  content: "\F1D0"
}

.ri-syringe-line:before {
  content: "\F1D1"
}

.ri-t-box-fill:before {
  content: "\F1D2"
}

.ri-t-box-line:before {
  content: "\F1D3"
}

.ri-t-shirt-2-fill:before {
  content: "\F1D4"
}

.ri-t-shirt-2-line:before {
  content: "\F1D5"
}

.ri-t-shirt-air-fill:before {
  content: "\F1D6"
}

.ri-t-shirt-air-line:before {
  content: "\F1D7"
}

.ri-t-shirt-fill:before {
  content: "\F1D8"
}

.ri-t-shirt-line:before {
  content: "\F1D9"
}

.ri-table-2:before {
  content: "\F1DA"
}

.ri-table-alt-fill:before {
  content: "\F1DB"
}

.ri-table-alt-line:before {
  content: "\F1DC"
}

.ri-table-fill:before {
  content: "\F1DD"
}

.ri-table-line:before {
  content: "\F1DE"
}

.ri-tablet-fill:before {
  content: "\F1DF"
}

.ri-tablet-line:before {
  content: "\F1E0"
}

.ri-takeaway-fill:before {
  content: "\F1E1"
}

.ri-takeaway-line:before {
  content: "\F1E2"
}

.ri-taobao-fill:before {
  content: "\F1E3"
}

.ri-taobao-line:before {
  content: "\F1E4"
}

.ri-tape-fill:before {
  content: "\F1E5"
}

.ri-tape-line:before {
  content: "\F1E6"
}

.ri-task-fill:before {
  content: "\F1E7"
}

.ri-task-line:before {
  content: "\F1E8"
}

.ri-taxi-fill:before {
  content: "\F1E9"
}

.ri-taxi-line:before {
  content: "\F1EA"
}

.ri-taxi-wifi-fill:before {
  content: "\F1EB"
}

.ri-taxi-wifi-line:before {
  content: "\F1EC"
}

.ri-team-fill:before {
  content: "\F1ED"
}

.ri-team-line:before {
  content: "\F1EE"
}

.ri-telegram-fill:before {
  content: "\F1EF"
}

.ri-telegram-line:before {
  content: "\F1F0"
}

.ri-temp-cold-fill:before {
  content: "\F1F1"
}

.ri-temp-cold-line:before {
  content: "\F1F2"
}

.ri-temp-hot-fill:before {
  content: "\F1F3"
}

.ri-temp-hot-line:before {
  content: "\F1F4"
}

.ri-terminal-box-fill:before {
  content: "\F1F5"
}

.ri-terminal-box-line:before {
  content: "\F1F6"
}

.ri-terminal-fill:before {
  content: "\F1F7"
}

.ri-terminal-line:before {
  content: "\F1F8"
}

.ri-terminal-window-fill:before {
  content: "\F1F9"
}

.ri-terminal-window-line:before {
  content: "\F1FA"
}

.ri-test-tube-fill:before {
  content: "\F1FB"
}

.ri-test-tube-line:before {
  content: "\F1FC"
}

.ri-text-direction-l:before {
  content: "\F1FD"
}

.ri-text-direction-r:before {
  content: "\F1FE"
}

.ri-text-spacing:before {
  content: "\F1FF"
}

.ri-text-wrap:before {
  content: "\F200"
}

.ri-text:before {
  content: "\F201"
}

.ri-thermometer-fill:before {
  content: "\F202"
}

.ri-thermometer-line:before {
  content: "\F203"
}

.ri-thumb-down-fill:before {
  content: "\F204"
}

.ri-thumb-down-line:before {
  content: "\F205"
}

.ri-thumb-up-fill:before {
  content: "\F206"
}

.ri-thumb-up-line:before {
  content: "\F207"
}

.ri-thunderstorms-fill:before {
  content: "\F208"
}

.ri-thunderstorms-line:before {
  content: "\F209"
}

.ri-ticket-2-fill:before {
  content: "\F20A"
}

.ri-ticket-2-line:before {
  content: "\F20B"
}

.ri-ticket-fill:before {
  content: "\F20C"
}

.ri-ticket-line:before {
  content: "\F20D"
}

.ri-time-fill:before {
  content: "\F20E"
}

.ri-time-line:before {
  content: "\F20F"
}

.ri-timer-2-fill:before {
  content: "\F210"
}

.ri-timer-2-line:before {
  content: "\F211"
}

.ri-timer-fill:before {
  content: "\F212"
}

.ri-timer-flash-fill:before {
  content: "\F213"
}

.ri-timer-flash-line:before {
  content: "\F214"
}

.ri-timer-line:before {
  content: "\F215"
}

.ri-todo-fill:before {
  content: "\F216"
}

.ri-todo-line:before {
  content: "\F217"
}

.ri-toggle-fill:before {
  content: "\F218"
}

.ri-toggle-line:before {
  content: "\F219"
}

.ri-tools-fill:before {
  content: "\F21A"
}

.ri-tools-line:before {
  content: "\F21B"
}

.ri-tornado-fill:before {
  content: "\F21C"
}

.ri-tornado-line:before {
  content: "\F21D"
}

.ri-trademark-fill:before {
  content: "\F21E"
}

.ri-trademark-line:before {
  content: "\F21F"
}

.ri-traffic-light-fill:before {
  content: "\F220"
}

.ri-traffic-light-line:before {
  content: "\F221"
}

.ri-train-fill:before {
  content: "\F222"
}

.ri-train-line:before {
  content: "\F223"
}

.ri-train-wifi-fill:before {
  content: "\F224"
}

.ri-train-wifi-line:before {
  content: "\F225"
}

.ri-translate-2:before {
  content: "\F226"
}

.ri-translate:before {
  content: "\F227"
}

.ri-travesti-fill:before {
  content: "\F228"
}

.ri-travesti-line:before {
  content: "\F229"
}

.ri-treasure-map-fill:before {
  content: "\F22A"
}

.ri-treasure-map-line:before {
  content: "\F22B"
}

.ri-trello-fill:before {
  content: "\F22C"
}

.ri-trello-line:before {
  content: "\F22D"
}

.ri-trophy-fill:before {
  content: "\F22E"
}

.ri-trophy-line:before {
  content: "\F22F"
}

.ri-truck-fill:before {
  content: "\F230"
}

.ri-truck-line:before {
  content: "\F231"
}

.ri-tumblr-fill:before {
  content: "\F232"
}

.ri-tumblr-line:before {
  content: "\F233"
}

.ri-tv-2-fill:before {
  content: "\F234"
}

.ri-tv-2-line:before {
  content: "\F235"
}

.ri-tv-fill:before {
  content: "\F236"
}

.ri-tv-line:before {
  content: "\F237"
}

.ri-twitch-fill:before {
  content: "\F238"
}

.ri-twitch-line:before {
  content: "\F239"
}

.ri-twitter-fill:before {
  content: "\F23A"
}

.ri-twitter-line:before {
  content: "\F23B"
}

.ri-typhoon-fill:before {
  content: "\F23C"
}

.ri-typhoon-line:before {
  content: "\F23D"
}

.ri-u-disk-fill:before {
  content: "\F23E"
}

.ri-u-disk-line:before {
  content: "\F23F"
}

.ri-ubuntu-fill:before {
  content: "\F240"
}

.ri-ubuntu-line:before {
  content: "\F241"
}

.ri-umbrella-fill:before {
  content: "\F242"
}

.ri-umbrella-line:before {
  content: "\F243"
}

.ri-underline:before {
  content: "\F244"
}

.ri-uninstall-fill:before {
  content: "\F245"
}

.ri-uninstall-line:before {
  content: "\F246"
}

.ri-unsplash-fill:before {
  content: "\F247"
}

.ri-unsplash-line:before {
  content: "\F248"
}

.ri-upload-2-fill:before {
  content: "\F249"
}

.ri-upload-2-line:before {
  content: "\F24A"
}

.ri-upload-cloud-2-fill:before {
  content: "\F24B"
}

.ri-upload-cloud-2-line:before {
  content: "\F24C"
}

.ri-upload-cloud-fill:before {
  content: "\F24D"
}

.ri-upload-cloud-line:before {
  content: "\F24E"
}

.ri-upload-fill:before {
  content: "\F24F"
}

.ri-upload-line:before {
  content: "\F250"
}

.ri-usb-fill:before {
  content: "\F251"
}

.ri-usb-line:before {
  content: "\F252"
}

.ri-user-2-fill:before {
  content: "\F253"
}

.ri-user-2-line:before {
  content: "\F254"
}

.ri-user-3-fill:before {
  content: "\F255"
}

.ri-user-3-line:before {
  content: "\F256"
}

.ri-user-4-fill:before {
  content: "\F257"
}

.ri-user-4-line:before {
  content: "\F258"
}

.ri-user-5-fill:before {
  content: "\F259"
}

.ri-user-5-line:before {
  content: "\F25A"
}

.ri-user-6-fill:before {
  content: "\F25B"
}

.ri-user-6-line:before {
  content: "\F25C"
}

.ri-user-add-fill:before {
  content: "\F25D"
}

.ri-user-add-line:before {
  content: "\F25E"
}

.ri-user-fill:before {
  content: "\F25F"
}

.ri-user-follow-fill:before {
  content: "\F260"
}

.ri-user-follow-line:before {
  content: "\F261"
}

.ri-user-heart-fill:before {
  content: "\F262"
}

.ri-user-heart-line:before {
  content: "\F263"
}

.ri-user-line:before {
  content: "\F264"
}

.ri-user-location-fill:before {
  content: "\F265"
}

.ri-user-location-line:before {
  content: "\F266"
}

.ri-user-received-2-fill:before {
  content: "\F267"
}

.ri-user-received-2-line:before {
  content: "\F268"
}

.ri-user-received-fill:before {
  content: "\F269"
}

.ri-user-received-line:before {
  content: "\F26A"
}

.ri-user-search-fill:before {
  content: "\F26B"
}

.ri-user-search-line:before {
  content: "\F26C"
}

.ri-user-settings-fill:before {
  content: "\F26D"
}

.ri-user-settings-line:before {
  content: "\F26E"
}

.ri-user-shared-2-fill:before {
  content: "\F26F"
}

.ri-user-shared-2-line:before {
  content: "\F270"
}

.ri-user-shared-fill:before {
  content: "\F271"
}

.ri-user-shared-line:before {
  content: "\F272"
}

.ri-user-smile-fill:before {
  content: "\F273"
}

.ri-user-smile-line:before {
  content: "\F274"
}

.ri-user-star-fill:before {
  content: "\F275"
}

.ri-user-star-line:before {
  content: "\F276"
}

.ri-user-unfollow-fill:before {
  content: "\F277"
}

.ri-user-unfollow-line:before {
  content: "\F278"
}

.ri-user-voice-fill:before {
  content: "\F279"
}

.ri-user-voice-line:before {
  content: "\F27A"
}

.ri-video-add-fill:before {
  content: "\F27B"
}

.ri-video-add-line:before {
  content: "\F27C"
}

.ri-video-chat-fill:before {
  content: "\F27D"
}

.ri-video-chat-line:before {
  content: "\F27E"
}

.ri-video-download-fill:before {
  content: "\F27F"
}

.ri-video-download-line:before {
  content: "\F280"
}

.ri-video-fill:before {
  content: "\F281"
}

.ri-video-line:before {
  content: "\F282"
}

.ri-video-upload-fill:before {
  content: "\F283"
}

.ri-video-upload-line:before {
  content: "\F284"
}

.ri-vidicon-2-fill:before {
  content: "\F285"
}

.ri-vidicon-2-line:before {
  content: "\F286"
}

.ri-vidicon-fill:before {
  content: "\F287"
}

.ri-vidicon-line:before {
  content: "\F288"
}

.ri-vimeo-fill:before {
  content: "\F289"
}

.ri-vimeo-line:before {
  content: "\F28A"
}

.ri-vip-crown-2-fill:before {
  content: "\F28B"
}

.ri-vip-crown-2-line:before {
  content: "\F28C"
}

.ri-vip-crown-fill:before {
  content: "\F28D"
}

.ri-vip-crown-line:before {
  content: "\F28E"
}

.ri-vip-diamond-fill:before {
  content: "\F28F"
}

.ri-vip-diamond-line:before {
  content: "\F290"
}

.ri-vip-fill:before {
  content: "\F291"
}

.ri-vip-line:before {
  content: "\F292"
}

.ri-virus-fill:before {
  content: "\F293"
}

.ri-virus-line:before {
  content: "\F294"
}

.ri-visa-fill:before {
  content: "\F295"
}

.ri-visa-line:before {
  content: "\F296"
}

.ri-voice-recognition-fill:before {
  content: "\F297"
}

.ri-voice-recognition-line:before {
  content: "\F298"
}

.ri-voiceprint-fill:before {
  content: "\F299"
}

.ri-voiceprint-line:before {
  content: "\F29A"
}

.ri-volume-down-fill:before {
  content: "\F29B"
}

.ri-volume-down-line:before {
  content: "\F29C"
}

.ri-volume-mute-fill:before {
  content: "\F29D"
}

.ri-volume-mute-line:before {
  content: "\F29E"
}

.ri-volume-off-vibrate-fill:before {
  content: "\F29F"
}

.ri-volume-off-vibrate-line:before {
  content: "\F2A0"
}

.ri-volume-up-fill:before {
  content: "\F2A1"
}

.ri-volume-up-line:before {
  content: "\F2A2"
}

.ri-volume-vibrate-fill:before {
  content: "\F2A3"
}

.ri-volume-vibrate-line:before {
  content: "\F2A4"
}

.ri-vuejs-fill:before {
  content: "\F2A5"
}

.ri-vuejs-line:before {
  content: "\F2A6"
}

.ri-walk-fill:before {
  content: "\F2A7"
}

.ri-walk-line:before {
  content: "\F2A8"
}

.ri-wallet-2-fill:before {
  content: "\F2A9"
}

.ri-wallet-2-line:before {
  content: "\F2AA"
}

.ri-wallet-3-fill:before {
  content: "\F2AB"
}

.ri-wallet-3-line:before {
  content: "\F2AC"
}

.ri-wallet-fill:before {
  content: "\F2AD"
}

.ri-wallet-line:before {
  content: "\F2AE"
}

.ri-water-flash-fill:before {
  content: "\F2AF"
}

.ri-water-flash-line:before {
  content: "\F2B0"
}

.ri-webcam-fill:before {
  content: "\F2B1"
}

.ri-webcam-line:before {
  content: "\F2B2"
}

.ri-wechat-2-fill:before {
  content: "\F2B3"
}

.ri-wechat-2-line:before {
  content: "\F2B4"
}

.ri-wechat-fill:before {
  content: "\F2B5"
}

.ri-wechat-line:before {
  content: "\F2B6"
}

.ri-wechat-pay-fill:before {
  content: "\F2B7"
}

.ri-wechat-pay-line:before {
  content: "\F2B8"
}

.ri-weibo-fill:before {
  content: "\F2B9"
}

.ri-weibo-line:before {
  content: "\F2BA"
}

.ri-whatsapp-fill:before {
  content: "\F2BB"
}

.ri-whatsapp-line:before {
  content: "\F2BC"
}

.ri-wheelchair-fill:before {
  content: "\F2BD"
}

.ri-wheelchair-line:before {
  content: "\F2BE"
}

.ri-wifi-fill:before {
  content: "\F2BF"
}

.ri-wifi-line:before {
  content: "\F2C0"
}

.ri-wifi-off-fill:before {
  content: "\F2C1"
}

.ri-wifi-off-line:before {
  content: "\F2C2"
}

.ri-window-2-fill:before {
  content: "\F2C3"
}

.ri-window-2-line:before {
  content: "\F2C4"
}

.ri-window-fill:before {
  content: "\F2C5"
}

.ri-window-line:before {
  content: "\F2C6"
}

.ri-windows-fill:before {
  content: "\F2C7"
}

.ri-windows-line:before {
  content: "\F2C8"
}

.ri-windy-fill:before {
  content: "\F2C9"
}

.ri-windy-line:before {
  content: "\F2CA"
}

.ri-wireless-charging-fill:before {
  content: "\F2CB"
}

.ri-wireless-charging-line:before {
  content: "\F2CC"
}

.ri-women-fill:before {
  content: "\F2CD"
}

.ri-women-line:before {
  content: "\F2CE"
}

.ri-wubi-input:before {
  content: "\F2CF"
}

.ri-xbox-fill:before {
  content: "\F2D0"
}

.ri-xbox-line:before {
  content: "\F2D1"
}

.ri-xing-fill:before {
  content: "\F2D2"
}

.ri-xing-line:before {
  content: "\F2D3"
}

.ri-youtube-fill:before {
  content: "\F2D4"
}

.ri-youtube-line:before {
  content: "\F2D5"
}

.ri-zcool-fill:before {
  content: "\F2D6"
}

.ri-zcool-line:before {
  content: "\F2D7"
}

.ri-zhihu-fill:before {
  content: "\F2D8"
}

.ri-zhihu-line:before {
  content: "\F2D9"
}

.ri-zoom-in-fill:before {
  content: "\F2DA"
}

.ri-zoom-in-line:before {
  content: "\F2DB"
}

.ri-zoom-out-fill:before {
  content: "\F2DC"
}

.ri-zoom-out-line:before {
  content: "\F2DD"
}

.ri-zzz-fill:before {
  content: "\F2DE"
}

.ri-zzz-line:before {
  content: "\F2DF"
}