@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

// $primary: #A07D43;
$primary: #c2b362;
// $secondary: #2A2927;
$secondary: #16294a;
$white: #ffffff;
$black: #000000;
$border-color: #dee2e6;

body {
  font-family: "Montserrat", sans-serif;
}

header {
  border-bottom: 1px solid #ced4da;
}

.auth-bg {
  width: 100%;
  min-height: 100vh;
  padding: 15px;
  background-image: url("../images/auth-bg.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    pointer-events: none;
  }

  &::before {
    content: "";
    // background-color: rgba($color: $primary, $alpha: .9);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.auth-form {
  color: $white;
  padding: 30px 40px;
  border-radius: 3px;
  // border: 1px solid rgba($color: $white, $alpha: 0.6);
  position: relative;
  z-index: 99;
  background-color: rgba($color: $secondary, $alpha: 0.8);
  max-width: 730px;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none;
  min-width: 300px;

  &::-webkit-scrollbar {
    width: 0;
  }

  h2 {
    font-weight: 200;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.6);
    margin-bottom: 30px;
  }

  input.form-control,
  select.form-select {
    border-color: rgba($color: $white, $alpha: 0.6);
    border-width: 2px;
    width: 300px;
    height: 50px;
    // background-color: transparent;
    background-color: rgba($color: $secondary, $alpha: 0.8);
    color: $white;
    border-radius: 0;
    font-size: 1.25rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &::placeholder {
      color: #888;
    }
  }

  .rmsc.multi-form-select {
    --rmsc-hover: #1e90ff;
    --rmsc-bg: #45546e;
    --rmsc-gray: #ffffff;
    --rmsc-main: #a3aab6;
    --rmsc-h: 50px;
    --rmsc-selected: #1e90ff;
    --rmsc-radius: 0px;
    font-size: 1.25rem !important;
  }

  .rmsc .dropdown-container {
    margin-right: 14px !important;
    display: flex !important;
    align-items: center !important;
    border-color: rgba(255, 255, 255, 0.6) !important;
    border-width: 2px !important;
    background-color: #182948 !important;
  }

  .rmsc .clear-selected-button {
    width: 24px !important;
    height: 24px !important;
  }

  .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23FFFFFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  }

  label {
    font-size: 14px;
  }

  button {
    height: 50px;
    width: 100%;
    display: block;
    color: $white;
    border-color: $primary;
    transition: all 0.5s;

    &:hover {
      border-color: $primary;
      color: $white;
      background-color: darken($color: $primary, $amount: 5);
    }
  }

  .form-check-input {
    width: 20px;
    height: 20px;
  }
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

input,
.form-control,
.form-select {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($color: $primary, $alpha: 0.25) !important;
  }
}

a {
  text-decoration: none;
}

.reg-text {
  position: relative;
  z-index: 99;
  color: $white;
  margin-top: 20px;
}

@mixin SearchCheckBox() {
  input {
    position: absolute;
    opacity: 0;
  }

  input:checked + label {
    background-color: $primary;
    color: $white;

    img {
      // filter: grayscale(100%) brightness(500%);
      // -webkit-filter: grayscale(100%) brightness(500%);
    }
  }

  label {
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    padding: 10px 5px;
    border: 1px solid $white;
    background-color: #f5f5f5;
    border-radius: 5px;

    &:hover {
      background-color: lighten($color: $primary, $amount: 50);
    }
  }
}

.search-items-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;

  li {
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    min-width: 75px;

    @media screen and (max-width: 1399px) {
      width: 80px;
    }

    @media screen and (max-width: 767px) {
      width: 16.66%;
    }

    @media screen and (max-width: 576px) {
      width: 25%;
    }

    @media screen and (max-width: 360px) {
      width: 33.33%;
    }

    @include SearchCheckBox();

    .diam-icon {
      display: block;
      // font-size: 35px;
      margin: 0 auto;
      height: 35px;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
  }
}

.search-row {
  padding: 20px 0;

  // &>[class*="col-"]:last-child {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}

.search-head {
  background-color: lighten($color: $secondary, $amount: 20);
  color: $white;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;

  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
}

.search-head-general {
  background-color: lighten($color: $secondary, $amount: 20);
  color: $white;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  width: 32%;

  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
}

.fancy-blocks {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;

  li {
    width: 25%;
    text-align: center;

    img {
      width: 40px;

      @media screen and (max-width: 767px) {
        width: 20px;
      }
    }

    @include SearchCheckBox();

    label {
      font-size: 14px;

      @media screen and (max-width: 767px) {
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    span {
      width: 50px;
      height: 50px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      background-color: $white;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      margin-bottom: 8px;

      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
      }
    }
  }
}

.overtone-list {
  padding-left: 0;

  li {
    display: inline-block;
    width: auto;
    @include SearchCheckBox();

    label {
      padding: 7px 10px;
      background-color: #f5f5f5;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.clarity-list {
  li {
    background-color: #f5f5f5;
  }
}

.multiSelect {
  .dropdown {
    & > button {
      border: 1px solid #ced4da;
    }
  }
}

.btn {
  padding-top: 8px;
  padding-bottom: 8px;
}

.search-accordian {
  .accordion-header {
    button {
      background-color: $primary;
      color: $white !important;

      &::after {
        display: none;
      }
    }
  }
}

.btn-active {
  color: $primary;
  font-weight: 600;
}

.heading {
  font-weight: bold;
}

.user-Header {
  padding: 15px 30px;
  background-color: $white;
  border-bottom: 0;
  box-shadow: 0 3px 6px rgba($color: $black, $alpha: 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  @media screen and (max-width: 767px) {
    padding: 10px 10px;
  }

  .logo {
    height: 50px;

    @media screen and (max-width: 767px) {
      height: 30px;
    }
  }
}

aside {
  left: 0;
  // width: 250px;
  width: 58px;
  padding-top: 100px;
  position: fixed;
  padding: 20px 10px 20px 0;
  transition: all 0.5s;
  background-color: $white;
  z-index: 99;
  height: 100vh;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
  overflow: hidden;

  &:hover {
    width: 250px;
  }

  @media screen and (max-width: 991px) {
    width: 50px;
    overflow: hidden;

    &:hover {
      width: 250px;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    a {
      display: block;
      font-size: 18px;
      font-weight: 300;
      color: $secondary;
      padding: 12px 10px 12px 20px;
      border-radius: 0 10px 10px 0;
      white-space: nowrap;

      @media screen and (max-width: 991px) {
        padding-left: 12px;
      }

      i {
        margin-right: 15px;
        color: $primary;
      }

      &:hover {
        color: $primary;
        background-color: lighten($color: $primary, $amount: 50);
      }

      &.active {
        background-color: $primary;
        color: $white;

        i {
          color: $white;
        }
      }
    }
  }
}

.userMain {
  // margin-left: 250px;
  margin-left: 50px;
  background-color: #f6f6f6;
  padding: 1px 30px;
  min-height: calc(100vh - 86px);

  @media screen and (max-width: 991px) {
    margin-left: 50px;
    padding: 1px 20px;
  }
}

.profile-drp {
  button {
    background-color: $white;
    border: 0;
    color: $secondary;
    font-weight: 600;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      padding-right: 0;
    }

    &::after {
      display: none;
    }

    .profile-icon {
      width: 40px;
      height: 40px;
      background-color: $primary;
      color: $white;
      font-weight: 300;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;

      @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.content-block {
  padding: 20px 30px;
  border-radius: 10px;
  background-color: $white;

  @media screen and (max-width: 767px) {
    padding: 1px 20px;
  }
}

.info-block {
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 15px 30px;

  @media screen and (max-width: 1599px) {
    padding: 17px;

    h2 {
      font-size: 22px;
    }

    h6 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 15px;

    h2 {
      font-size: 19px;
    }

    h6 {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 10px;

    h2 {
      font-size: 20px;
    }

    h6 {
      font-size: 14px;
    }
  }
}

.k-button-solid-primary {
  background-color: $secondary;
  border-color: $secondary;

  &:hover {
    background-color: lighten($color: $secondary, $amount: 10);
    border-color: $secondary;
  }
}

.k-master-row.k-selected.k-table-alt-row .k-grid-content-sticky,
.k-master-row.k-selected.k-table-alt-row .k-grid-row-sticky,
.k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected,
.k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: lighten($color: $primary, $amount: 20);
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  // background-color: #ededed;
  background-color: $primary;
}
.k-master-row:hover .k-grid-content-sticky,
.k-master-row:hover .k-grid-row-sticky,
.k-master-row.k-hover .k-grid-content-sticky,
.k-master-row.k-hover .k-grid-row-sticky {
  background-color: $primary;
}

.k-master-row.k-selected:hover .k-grid-content-sticky,
.k-master-row.k-selected:hover .k-grid-row-sticky,
.k-master-row.k-selected.k-hover .k-grid-content-sticky,
.k-master-row.k-selected.k-hover .k-grid-row-sticky,
.k-master-row:hover td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover td.k-grid-content-sticky.k-selected,
.k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected {
  background-color: lighten($color: $primary, $amount: 20);
  // background-color: $primary;
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
  background-color: lighten($color: $primary, $amount: 20);
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: $primary;
  border-color: $primary;
}

.k-button-flat-primary {
  color: $primary;
}

.dropdown-menu {
  padding: 0;
  border: 0;
  box-shadow: 0 3px 8px rgba($color: $black, $alpha: 0.16);
  border-radius: 0;

  .dropdown-item {
    padding: 10px 15px;

    &:hover {
      background-color: lighten($color: $primary, $amount: 50);
    }

    &:active {
      background-color: transparent;
      color: inherit;

      i {
        color: $white !important;
      }
    }
  }
}

.searchModal {
  position: absolute;
  background-color: $white;
  padding: 50px;
}

.detail-frame {
  height: 500px;

  @media screen and (max-width: 767px) {
    height: 400px;
  }

  @media screen and (max-width: 576px) {
    height: 300px;
  }
}

.detail-table {
  td,
  th {
    padding: 5px;
    font-size: 14px;
  }
}

.user-footer {
  background-color: $secondary;
  color: $white;
  padding: 15px;
  margin-top: 0;
}

.navigate-buttons {
  button {
    @media screen and (max-width: 376px) {
      padding: 7px;
    }
  }
}

.dashblock-card {
  border: 0;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.16);
  position: relative;
  border-left: 7px solid $primary;
  margin-bottom: 1.5rem;
  color: $secondary;
  transition: all 0.5s;

  .card-body {
    padding: 24px 24px 24px 20px;
    min-height: 130px;

    @media screen and (max-width: 767px) {
      min-height: 115px;
      padding: 16px;
    }

    h5 {
      position: relative;
      z-index: 1;

      @media screen and (max-width: 992px) {
        font-size: 16px;
      }
    }

    h2 {
      font-size: 40px;
      position: relative;
      z-index: 1;

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
  }

  .dashblock-icon {
    // width: 60px;
    // height: 60px;
    // border-radius: 5px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: absolute;
    right: 0;
    top: 8px;
    font-size: 100px;
    line-height: 100%;
    // background-color: lighten($color: $primary, $amount: 50);
    color: lighten($color: $primary, $amount: 35);
    transition: all 0.5s;

    @media screen and (max-width: 767px) {
      font-size: 75px;
    }
  }

  &:hover {
    color: $primary;
    background-color: lighten($color: $primary, $amount: 30);

    .dashblock-icon {
      color: $white;
    }
  }
}

.form-control,
input,
textarea,
select {
  &:focus {
    border-color: $primary;
  }
}

.comparison-tbl {
  tr {
    &:first-child {
      td {
        border: 0;
      }
    }

    td {
      border: 1px solid #dee2e6;
      font-weight: 600;

      img {
        height: 150px;
      }

      &:first-child {
        border-left: 0;
        width: 200px;
        font-weight: 300;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.detail-modal {
  .modal-body {
    padding: 40px;
  }

  label {
    font-size: 14px;
  }

  h6 {
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.notFound-block {
  h1 {
    font-size: 15rem;
    text-align: center;
    color: $secondary;
    font-style: oblique;
    font-weight: 700;
    text-shadow: 3px 3px 3px rgba($color: $primary, $alpha: 0.9);
  }
}

.dash-card {
  border: 0;
  box-shadow: 0 3px 6px rgba($color: $black, $alpha: 0.16);
  margin-top: 2rem;
  padding: 10px;

  table {
    td,
    th {
      font-size: 14px;
      padding: 10px;
    }

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.notification-drp {
  .dropdown-menu {
    min-width: 25rem;
    max-height: 500px;
    overflow-y: auto;
    scrollbar-width: thin;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $border-color;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

    .dropdown-item {
      &:first-child {
        background-color: $white !important;
        cursor: default;
        pointer-events: none;
        padding: 10px 20px;
      }

      padding: 15px;
      padding-left: 40px;
      border-bottom: 1px solid $border-color;
      position: relative;

      &.new {
        &::after {
          content: "";
          width: 7px;
          height: 7px;
          background-color: $primary;
          border-radius: 7px;
          position: absolute;
          top: 24px;
          left: 18px;
        }
      }

      .noti-time {
        display: block;
        color: #888;
        font-size: 70%;
        margin-top: 5px;
      }
    }
  }
}

.globalSearch {
  margin-left: 80px;
  position: relative;

  @media screen and (max-width: 767px) {
    margin-left: 30px;
    display: none;
  }

  input {
    border-radius: 50px;
    width: 30rem;
    height: 50px;
    border: 0;
    background-color: #f5f5f5;
    padding-left: 30px;
    padding-right: 60px;

    @media screen and (max-width: 1199px) {
      width: 20rem;
    }

    &:focus {
      background-color: #f5f5f5;
    }
  }
}

.btn-search {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  border: 0;

  &:focus,
  &:active {
    outline: 0;
    border: 0;
  }
}

.adBanner {
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  img {
    object-fit: center;
    height: 100%;
    width: 100%;
  }
}

.nav-tabs .nav-link {
  color: $secondary;
  border: 0;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $primary;
  font-weight: 500;
  border-color: $primary;
}

.btn:disabled {
  color: $white;
  opacity: 0.9;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.talk_btn {
  background: #c2b362;
  border-radius: 12px;
  // font-family: Title-Semibold;
  padding: 15px 30px;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.talk_btn span {
  padding-right: 6px;
}

.talk_btn:hover {
  background-color: #16294a;
}

.starmelee_checkbox {
  font-weight: 600;
  min-width: 100px;
  margin-right: 8px;
  margin-bottom: 10px;
  border: 2px solid #c2b362;
  color: #c2b362;

  &:hover {
    border: 2px solid #c2b362;
    color: #c2b362;
  }
}

.back_button {
  background: #c2b362;
  border-radius: 12px;
  // font-family: Title-Semibold;
  padding: 10px 20px;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.back_button:hover {
  background-color: #16294a;
  color: white;
}

.requireField {
  border-color: red !important;
}

.inquiry-form {
  // border: 1px solid #E9ECEF;
  box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.16);
  border-radius: 5px;
  max-width: 750px;
  padding: 25px;
}

.k-grid-container .k-table-tbody,
.k-grid-header .k-table-thead {
  font-size: 13px !important;
}

.k-grid-md .k-table-th,
.k-grid-md td,
.k-grid-md .k-table-td {
  padding-block: 6px;
  padding-inline: 4px;
}

// .k-grid-header,
// .k-grid-footer {
//   padding-inline-end: 0px;
// }

.k-grid-content-sticky {
  .btn:disabled {
    color: $black;
    opacity: 0.5;
    border: 0;
  }
}

.searchType {
  background-color: transparent !important;
  border: none;
  color: #c2b362 !important;
  text-decoration: underline;
  text-decoration-thickness: 5px;
}

