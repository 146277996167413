@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

// $primary: #A07D43;
// $secondary: #2A2927;
$primary: #C2B362;
$secondary: #16294A;
$white: #FFFFFF;
$black: #000000;
$border-color: #DEE2E6;

header {
      position: sticky;
      z-index: 99;
      top: 0;
      transition: all 0.5s;

      .navbar {
            background-color: transparent !important;
      }
}

.navbar-brand {
      img {
            // width: 200px;
            width: 150px;

            @media screen and (max-width: 1199px) {
                  width: 100px;
            }
      }
}

.navbar {
      .nav-link {
            padding: 0 13px !important;
            color: rgba($color: $white, $alpha: 0.5);
            font-weight: 500;
            transition: all 0.5s;

            @media screen and (max-width: 1199px) {
                  padding: 0 10px !important;
                  font-size: 14px;
            }
      }

      .dropdown-toggle::after {
            display: none;
      }

      .dropdown {
            &-menu {
                  border: 0;
                  border-radius: 0;
                  padding: 0;
                  box-shadow: 0 3px 6px rgba($color: $black, $alpha: .16);
            }

            &-item {
                  padding: 10px 15px;
            }
      }
}

.nav-item.dropdown:hover .dropdown-menu {
      display: block;
}

.navbar {
      .nav-link {
            &:hover {
                  opacity: 1;
                  text-shadow: 0 0 40px rgba($color: $white, $alpha: 1);
            }

            color: $white;
      }
}

.main-banner {
      // background-image: url(../images/banner.webp);
      background-color: $secondary;
      width: 100%;
      height: 100vh;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -94px;

      @media screen and (max-width: 1199px) {
            margin-top: -88px;
      }

      canvas {
            pointer-events: none;
      }

      img {
            max-width: 700px;

            @media screen and (max-width: 992px) {
                  max-width: 450px;
            }

            @media screen and (max-width: 576px) {
                  max-width: 300px;
            }
      }
}

.nav-login.nav-link {
      border: 1px solid $white;
      border-radius: 5px;
      padding: 10px 15px !important;

      @media screen and (max-width: 1199px) {
            padding: 5px 7px !important;
      }
}

.fixed {
      // background-color: $white;
      background-color: $secondary;
      box-shadow: 0 3px 6px rgba($color: $black, $alpha: .16);

      .navbar {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            // .navbar-toggler {
            //       background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
            //       ;
            //       background-repeat: no-repeat;
            //       background-position: center;
            //       z-index: 99;
            // }
      }

      // .nav-link {
      //       color: $secondary;
      // }

      .nav-login.nav-link {
            // border-color: $secondary;
            border-color: $white;
      }
}

section {
      margin-top: 100px;

      @media screen and (max-width: 992px) {
            margin-top: 75px;
      }
}

.heading {
      font-size: 36px;
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
            font-size: 30px;
      }

}

.desc {
      margin-bottom: 20px;
}

.diamond-sec {
      background-color: $secondary;
      padding-top: 70px;
      padding-bottom: 70px;

      @media screen and (max-width: 992px) {
            padding-top: 30px;
            padding-bottom: 40px;
      }
}

.shape-block {
      color: rgba($color: $white, $alpha: 0.5);
      padding-top: 30px;
      font-size: 20px;
      text-align: center;
      transition: all 0.5s;

      span {
            font-size: 100px;
            color: $white;
            display: block;
            margin-bottom: 20px;
            opacity: 0.8;
            transition: all 0.5s;

            @media screen and (max-width: 1199px) {
                  font-size: 75px;
            }
      }

      svg {
            width: 100px;
            height: 100px;
            display: block;
            margin: 0 auto;
      }

      &:hover {
            span {
                  opacity: 1;
                  text-shadow: 0 0 40px rgba($color: $white, $alpha: 1);
            }

            color: $white;
      }
}

.labgrown-card {
      padding: 30px;
      border: 0;
      box-shadow: 0 3px 10px rgba($color: $black, $alpha: .16);
      margin-bottom: 1.5rem;
      transition: all .5s;
      min-height: 280px;

      @media screen and (max-width: 767px) {
            padding: 20px;
      }

      img {
            width: 64px;
      }

      &:hover {
            box-shadow: 0 8px 30px rgba($color: $black, $alpha: .30);
            background-color: #16294A;
            color: white;
            transition: all .5s;
      }
}

footer {
      background-color: $secondary;
      padding: 50px 20px;
      margin-top: 150px;

      @media screen and (max-width: 991px) {
            margin-top: 50px;
      }
}

.logo {
      max-height: 100px;
}

.footer-links {
      padding-left: 0;
      list-style: none;
      margin-top: 30px;

      li {
            display: inline-block;
            padding: 0 15px;

            a {
                  color: $white;
            }
      }

}

.footer-li {
      &:hover {
            opacity: 1;
            text-shadow: 0 0 40px rgba($color: $white, $alpha: 1);
            color: $white;
      }
}

.copyright-sec {
      padding: 20px;
      color: $white;
      border-top: 1px solid rgba($color: $white, $alpha: 0.1);
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
            flex-direction: column;
      }
}

.social-link {
      a {
            display: inline-block;
            color: $white;
            padding: 0 10px;
      }
}

p {
      @media screen and (max-width: 1199px) {
            font-size: 14px;
      }
}

.slick-prev {
      @media screen and (max-width: 576px) {
            left: -5px;
      }
}

.slick-next {
      @media screen and (max-width: 576px) {
            right: -5px;
      }
}

.banner {
      background-image: url(../images/banner.jpg);
      background-size: cover;
      padding: 230px 20px 50px;
      background-position: bottom;
      position: relative;
      min-height: 450px;
      margin-top: -130px;

      @media screen and (max-width: 992px) {
            padding: 180px 20px 50px;
            min-height: 375px;
      }

      @media screen and (max-width: 767px) {
            padding: 140px 20px 50px;
            min-height: auto;
      }

      &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: $secondary, $alpha: .70);
            width: 100%;
            height: 100%;
      }

      &::before {
            content: "";
            position: absolute;
            bottom: -26px;
            left: 0;
            background-image: url(../images/border.png);
            width: 100%;
            height: 26px;
            background-size: contain;
            background-repeat: no-repeat;
      }

      .pageName {
            font-size: 60px;
            position: relative;
            z-index: 1;
            color: $white;
            font-weight: 600;
            text-shadow: 0 3px 6px rgba($color: $black, $alpha: .16);

            @media screen and (max-width: 767px) {
                  font-size: 40px;
            }
      }
}

.contact-form {
      padding: 50px;
      box-shadow: 0 3px 16px rgba($color: $black, $alpha: .16);
      padding-left: 200px;

      @media screen and (max-width: 1199px) {
            padding-left: 250px;
      }

      @media screen and (max-width: 991px) {
            padding: 30px;
      }

      .form-control {
            border-radius: 0;
            padding: 10px 15px;

            &:focus {
                  border-color: $primary;
            }
      }

      label {
            margin-bottom: 8px;
      }
}

.contact-info {
      background-color: $primary;
      padding: 50px 30px;
      position: absolute;
      bottom: 50px;
      left: 75px;
      color: $white;
      z-index: 9;

      @media screen and (max-width: 991px) {
            position: relative;
            top: unset;
            left: unset;
      }

      h5 {
            font-size: 16px;
            font-weight: normal;
      }

      p {
            opacity: 0.7;
      }
}

.offcanvas {
      background-color: $secondary;

      .nav-link {
            color: $white;

            &.active {
                  color: $primary !important;
            }
      }

      .logo {
            width: 200px;
      }
}

.shape-card {
      span {
            font-size: 75px;
      }

      padding: 30px;
      border: 0;
      box-shadow: 0 3px 10px rgb(0 0 0 / 16%);
      margin-bottom: 1.5rem;
      transition: all 0.5s;
      min-height: 280px;
      border-radius: 5px;
      min-height: 305px;

      &:hover {
            box-shadow: 0 8px 30px rgba($color: $black, $alpha: .30);
            background-color: #16294A;
            color: white;
            transition: all .5s;
      }
}

.diam-componants {
      img {
            width: 64px;
            margin-bottom: 20px;
      }

      li {
            margin-bottom: 8px;
      }

      ul {
            padding-left: 1.5rem;
      }
}


.main-bg {
      background-image: url(../images/delight-bg.jpg);
      backdrop-filter: blur(05);
}

.promotional-popup {
      .modal-header {
            padding: 0;
            border-bottom: 0;
      }

      .btn-close {
            position: absolute;
            top: -10px;
            right: -10px;
            // width: 45px;
            // height: 45px;
            border-radius: 50px;
            color: $white;
            border: 2px solid $border-color;
            z-index: 99;
            background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
      }

      .modal-content {
            border: 0;
      }
}

.form-error {
      display: inline-block;
      margin-top: 8px;
      font-size: 14px;
      color: #dc3545;
}

.optInputs {
      width: 60px !important;
      height: 60px !important;
      margin: 5px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
      }
}